import {
  path,
  assocPath,
  compose,
  identity,
  max,
  assoc,
  evolve,
  inc,
  dec,
  append,
  filter,
  not,
  converge,
  equals,
} from 'ramda';

import { Feature } from './FeaturesSurveyStep/constants';
import { CompanyForm } from './ProfileStep/CompanyFormSelector/companyForm';
import { CompanySize } from './ProfileStep/CompanySizeSelector/companySize';
import {
  VALIDATE_EMAIL,
  EMAIL_VALIDATED,
  SIGN_UP_FAILED,
  CHANGE_FIELD,
  SUBMIT_STEP,
  PREVIOUS_STEP,
  SET_REFERRAL_TOKEN,
  PREFILL_FIELDS,
  SET_INDICATE_FIRST_STEP,
  INIT_SIGNUP,
  ADD_SIGNUP_FEATURE,
  REMOVE_SIGNUP_FEATURE,
  SET_IDENTITY_PROVIDER_TOKEN,
  SET_IDENTITY_PROVIDER_ERROR,
} from './actions';
import { STEP_EMAIL, STEP_PROFILE, STEP_REGION, STEP_SUBMITTING, STEP_FEATURES, STEPS_ORDER } from './constants';

const initialState = {
  emailValidation: {
    email: undefined,
    inProgress: undefined,
    error: undefined,
  },
  identityProvider: {
    error: undefined,
    token: undefined,
  },
  signupFailed: false,
  steps: {
    STEP_EMAIL: {
      email: '',
      password: '',
      termsOfService: {
        text: '',
      },
      marketingEmailsConsent: {
        accepted: false,
        text: '',
      },
    },
    STEP_PROFILE: {
      firstName: '',
      lastName: '',
      companyName: '',
      companyForm: undefined,
      companySize: undefined,
      telephone: '',
      telephoneCountry: '',
    },
    STEP_REGION: {
      country: '',
      language: '',
      timeZone: '',
      currency: '',
    },
    STEP_FEATURES: {
      signupFeatures: [],
    },
  },
  syncTelephoneCountryWithCountry: true,
  stepIndex: STEPS_ORDER.indexOf(STEP_EMAIL),
  maxStepIndexSeen: STEPS_ORDER.indexOf(STEP_EMAIL),
  referralToken: undefined,
  indicateFirstStep: false,
};

const toPrevStep = evolve({ stepIndex: dec });
const toNextStep = evolve({ stepIndex: inc });

export default (state = initialState, action) => {
  if (action.type === VALIDATE_EMAIL) {
    return {
      ...state,
      emailValidation: {
        email: action.payload.email,
        inProgress: true,
        error: undefined,
      },
    };
  }

  if (action.type === EMAIL_VALIDATED && action.payload.email === state.emailValidation.email) {
    return {
      ...state,
      emailValidation: {
        email: action.payload.email,
        inProgress: false,
        error: action.payload.error,
      },
    };
  }

  if (action.type === SIGN_UP_FAILED) {
    const error = action.payload?.error;

    if (!error) {
      return {
        ...toPrevStep(state),
        signupFailed: true,
      };
    }

    return {
      ...state,
      stepIndex: STEPS_ORDER.indexOf(STEP_EMAIL),
      emailValidation: {
        ...state.emailValidation,
        inProgress: false,
        email: state.steps[STEP_EMAIL].email,
        error: error.error,
      },
    };
  }

  if (action.type === CHANGE_FIELD) {
    const { stepIndex, syncTelephoneCountryWithCountry } = state;

    return compose(
      syncTelephoneCountryWithCountry && action.payload.fieldName === 'telephoneCountry'
        ? assocPath(['steps', STEP_REGION, 'country'], action.payload.value)
        : identity,
      (action.payload.fieldName === 'email' || action.payload.fieldName === 'password') && action.payload.value !== ''
        ? assocPath(['identityProvider', 'token'], undefined)
        : identity,
      action.payload.fieldName === 'companyForm' &&
        (action.payload.value === CompanyForm.SelfEmployed || action.payload.value === CompanyForm.PersonalUse)
        ? compose(
            assocPath(['steps', STEPS_ORDER[stepIndex], 'companySize'], CompanySize.Range1),
            assocPath(['steps', STEP_FEATURES, 'signupFeatures'], [Feature.InvoicesAndPayments]),
          )
        : identity,
      action.payload.fieldName === 'companyForm' && action.payload.value === CompanyForm.Business
        ? compose(
            assocPath(['steps', STEPS_ORDER[stepIndex], 'companySize'], undefined),
            assocPath(
              ['steps', STEP_FEATURES, 'signupFeatures'],
              [Feature.InvoicesAndPayments, Feature.ProjectManagement],
            ),
          )
        : identity,
      assocPath(['steps', STEPS_ORDER[stepIndex], action.payload.fieldName], action.payload.value),
    )(state);
  }

  if (action.type === PREVIOUS_STEP) {
    const newState = toPrevStep(state);
    return newState.stepIndex === 0 ? { ...newState, identityProviderToken: null } : newState;
  }

  if (action.type === SUBMIT_STEP) {
    const newState = toNextStep(state);
    return {
      ...newState,
      syncTelephoneCountryWithCountry:
        newState.syncTelephoneCountryWithCountry && newState.stepIndex !== STEPS_ORDER.indexOf(STEP_REGION),
      signingUp: newState.stepIndex === STEPS_ORDER.indexOf(STEP_SUBMITTING),
      signupFailed: newState.stepIndex === STEPS_ORDER.indexOf(STEP_SUBMITTING) ? false : state.signupFailed,
      maxStepIndexSeen: max(state.maxStepIndexSeen, newState.stepIndex),
    };
  }

  if (action.type === PREFILL_FIELDS) {
    const {
      email,
      firstName,
      lastName,
      companyName,
      companyForm,
      companySize,
      telephone,
      telephoneCountry,
      country,
      language,
      timeZone,
      currency,
      signupFeatures,
    } = action.payload;
    const { maxStepIndexSeen } = state;

    return compose(
      state.steps[STEP_EMAIL].email ? identity : assocPath(['steps', STEP_EMAIL, 'email'], email),
      maxStepIndexSeen >= STEPS_ORDER.indexOf(STEP_PROFILE)
        ? identity
        : compose(
            assocPath(['steps', STEP_PROFILE, 'firstName'], firstName),
            assocPath(['steps', STEP_PROFILE, 'lastName'], lastName),
            assocPath(['steps', STEP_PROFILE, 'companyName'], companyName),
            assocPath(['steps', STEP_PROFILE, 'companyForm'], companyForm),
            assocPath(['steps', STEP_PROFILE, 'companySize'], companySize),
            assocPath(['steps', STEP_PROFILE, 'telephone'], telephone),
            assocPath(['steps', STEP_PROFILE, 'telephoneCountry'], telephoneCountry),
          ),
      maxStepIndexSeen >= STEPS_ORDER.indexOf(STEP_REGION)
        ? identity
        : compose(
            assocPath(['steps', STEP_REGION, 'country'], country),
            assocPath(['steps', STEP_REGION, 'language'], language),
            assocPath(['steps', STEP_REGION, 'timeZone'], timeZone),
            assocPath(['steps', STEP_REGION, 'currency'], currency),
          ),
      maxStepIndexSeen >= STEPS_ORDER.indexOf(STEP_FEATURES)
        ? identity
        : assocPath(['steps', STEP_FEATURES, 'signupFeatures'], signupFeatures),
    )(state);
  }

  if (action.type === ADD_SIGNUP_FEATURE) {
    const { feature } = action.payload;
    const signupFeaturesPath = ['steps', STEP_FEATURES, 'signupFeatures'];

    const isNotRemovedFeature = compose(not, equals(feature));

    return converge(assocPath(signupFeaturesPath), [
      compose(append(feature), filter(isNotRemovedFeature), path(signupFeaturesPath)),
      identity,
    ])(state);
  }

  if (action.type === REMOVE_SIGNUP_FEATURE) {
    const { feature } = action.payload;
    const signupFeaturesPath = ['steps', STEP_FEATURES, 'signupFeatures'];

    const isNotRemovedFeature = compose(not, equals(feature));

    return converge(assocPath(signupFeaturesPath), [
      compose(filter(isNotRemovedFeature), path(signupFeaturesPath)),
      identity,
    ])(state);
  }

  if (action.type === SET_REFERRAL_TOKEN) {
    return assoc('referralToken', action.payload, state);
  }

  if (action.type === SET_IDENTITY_PROVIDER_ERROR) {
    return assocPath(['identityProvider', 'error'], action.payload, state);
  }

  if (action.type === SET_IDENTITY_PROVIDER_TOKEN) {
    return compose(
      assocPath(['identityProvider', 'token'], action.payload),
      assocPath(['steps', STEP_EMAIL, 'email'], action.payload.email ?? state.steps.STEP_EMAIL.email),
      assocPath(['steps', STEP_EMAIL, 'password'], ''),
      assocPath(['steps', STEP_PROFILE, 'firstName'], action.payload.firstname ?? state.steps.STEP_PROFILE.firstName),
      assocPath(['steps', STEP_PROFILE, 'lastName'], action.payload.lastname ?? state.steps.STEP_PROFILE.lastName),
      assocPath(['steps', STEP_PROFILE, 'telephone'], action.payload.phone ?? state.steps.STEP_PROFILE.telephone),
    )(state);
  }

  if (action.type === SET_INDICATE_FIRST_STEP) {
    return assoc('indicateFirstStep', action.payload, state);
  }

  if (action.type === INIT_SIGNUP) {
    const planningBoosterEnabled = action.payload.planningBoosterEnabled === true;

    return compose(assoc('planningBoosterEnabled', planningBoosterEnabled))(state);
  }

  return state;
};
