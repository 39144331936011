import { all, fork, takeLatest, call, put } from 'redux-saga/effects';

import { AuthenticatedAPI } from '../../infrastructure/api';

import {
  fetchUserInformationSuccess,
  FETCH_USER_INFORMATION,
  fetchUserInformationFailure,
  updateProfileSuccess,
  updateProfileFailure,
  UPDATE_PROFILE,
} from './actions';

export function* fetchCurrentUserInformation() {
  try {
    const { data: user } = yield call(AuthenticatedAPI.users.me);
    yield put(fetchUserInformationSuccess(user));
  } catch (error) {
    yield put(fetchUserInformationFailure(error));
  }
}

export function* updateProfile({ payload }) {
  try {
    yield call(AuthenticatedAPI.profile.update, {
      function: payload.function,
      telephones: [
        ...(payload.phone ? [{ type: 'phone', number: payload.phone }] : []),
        ...(payload.mobile ? [{ type: 'mobile', number: payload.mobile }] : []),
      ],
    });
    yield put(updateProfileSuccess());
  } catch (error) {
    yield put(updateProfileFailure(error));
  }
}

export function* updateProfileWatcher() {
  yield takeLatest(UPDATE_PROFILE, updateProfile);
}

export function* fetchCurrentUserInformationWatcher() {
  yield takeLatest(FETCH_USER_INFORMATION, fetchCurrentUserInformation);
}

export default function* () {
  yield all([fetchCurrentUserInformationWatcher, updateProfileWatcher].map(fork));
}
