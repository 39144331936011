export enum ConsentCategory {
  Necessary = 'necessary',
  Preferences = 'preferences',
  Statistics = 'statistics',
  Marketing = 'marketing',
}

export type Consent = {
  [key in ConsentCategory]: boolean;
};

export interface CookiesConsentPayload {
  allowed: ConsentCategory[];
  disallowed: ConsentCategory[];
}
