import { createAction } from '@reduxjs/toolkit';

export const FETCH_USER_INFORMATION = 'Signup/CurrentUser/FETCH_USER_INFORMATION';
export const FETCH_USER_INFORMATION_SUCCESS = 'Signup/CurrentUser/FETCH_USER_INFORMATION_SUCCESS';
export const FETCH_USER_INFORMATION_FAILURE = 'Signup/CurrentUser/FETCH_USER_INFORMATION_FAILURE';

export const fetchUserInformation = createAction(FETCH_USER_INFORMATION);
export const fetchUserInformationSuccess = createAction(FETCH_USER_INFORMATION_SUCCESS);
export const fetchUserInformationFailure = createAction(FETCH_USER_INFORMATION_FAILURE);

export const UPDATE_PROFILE = 'Signup/CurrentUser/UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'Signup/CurrentUser/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'Signup/CurrentUser/UPDATE_PROFILE_FAILURE';

export const updateProfile = createAction(UPDATE_PROFILE);
export const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS);
export const updateProfileFailure = createAction(UPDATE_PROFILE_FAILURE);
