import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';

import featureFlags from './featureFlags.dist';
import { Provider } from './localContext';

export const withFeatureFlagProvider = (WrappedComponent) => {
  if (__DEV__) {
    return class extends React.PureComponent {
      state = {
        flags: {},
      };

      ldClient = {
        identify: (newUser, hash, callback) => {
          return new Promise((resolve) => {
            setTimeout(() => {
              const newFlags = this.state.flags;
              console.log('User context switched in LaunchDarkly ', newUser, hash);
              this.setState({
                flags: newFlags,
              });
              callback && callback(null, newFlags);
              resolve();
            }, 500);
          });
        },
      };

      async componentDidMount() {
        const localFeatureFlags = await import('./featureFlags');
        this.setState({ flags: { ...featureFlags, ...localFeatureFlags.default } });
      }

      render() {
        return (
          <Provider value={{ ...this.state, ldClient: this.ldClient }}>
            <WrappedComponent {...this.props} />
          </Provider>
        );
      }
    };
  }

  return withLDProvider({
    clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID,
    context: {
      kind: 'user',
      anonymous: true,
    },
    flags: featureFlags,
    options: {
      streaming: false,
      sendEvents: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(WrappedComponent);
};
