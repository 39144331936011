import { call } from 'redux-saga/effects';

export const waitForRecaptchaReady = () =>
  new Promise<void>((resolve) => {
    if (RECAPTCHA_KEY) {
      window.grecaptcha.enterprise.ready(async () => {
        resolve();
      });
    } else {
      resolve();
    }
  });

export const getRecaptchaToken = function* (action: string) {
  if (!RECAPTCHA_KEY) {
    return undefined;
  }

  const recaptchaToken: Awaited<ReturnType<typeof window.grecaptcha.enterprise.execute>> = yield call(
    window.grecaptcha.enterprise.execute,
    RECAPTCHA_KEY,
    {
      action: action,
    },
  );
  return recaptchaToken;
};
