export interface IdentityProviderTokenPayload {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export enum IdentityProvider {
  Google = 'google',
  Apple = 'apple',
  Microsoft = 'microsoft',
}

export enum IdentityProviderErrorType {
  AlreadyInUse = 'already_in_use',
  InvalidToken = 'invalid_token',
}

export enum IdentityProviderMessageType {
  Finished = 'identityProviderMessage.finished',
  Failed = 'identityProviderMessage.failed',
}

export interface IdentityProviderError {
  message: string | null;
  provider: string;
}

export interface IdentityProviderSignupFinishedMessage {
  type: IdentityProviderMessageType.Finished;
  provider: string | null;
  token: string | null;
}

export function isIdentityProviderSignupFinishedMessageEvent(
  event: MessageEvent,
): event is MessageEvent<IdentityProviderSignupFinishedMessage> {
  return event.data.type === IdentityProviderMessageType.Finished;
}

export interface IdentityProviderSignupFailedMessage {
  type: IdentityProviderMessageType.Failed;
  provider: string | null;
  error: string | null;
}

export function isIdentityProviderSignupFailedMessageEvent(
  event: MessageEvent,
): event is MessageEvent<IdentityProviderSignupFailedMessage> {
  return event.data.type === IdentityProviderMessageType.Failed;
}
