import {
  VALIDATE_INVITE,
  VALIDATE_INVITE_SUCCESS,
  VALIDATE_INVITE_FAILURE,
  ACCEPT_INVITE,
  ACCEPT_INVITE_FAILURE,
  REASSIGN_INVITE,
  REASSIGN_INVITE_FAILURE,
  REASSIGN_INVITE_SUCCESS,
  SET_CHANNEL,
} from './actions';

const initialState = {
  inviteTokenError: null,
  isValidatingInvite: false,
  validatingInviteError: null,
  acceptInviteError: null,
  isAcceptingInvite: false,
  isReassigning: false,
  channel: null,
  reassignedSuccessfully: false,
  reassigningError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VALIDATE_INVITE:
      return {
        ...state,
        isValidatingInvite: true,
      };
    case VALIDATE_INVITE_SUCCESS:
      return {
        ...state,
        isValidatingInvite: false,
        inviteTokenError: payload,
      };
    case VALIDATE_INVITE_FAILURE:
      return {
        ...state,
        isValidatingInvite: false,
        validatingInviteError: payload,
      };
    case ACCEPT_INVITE:
      return {
        ...state,
        isAcceptingInvite: true,
      };
    case ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        acceptInviteError: payload,
        isAcceptingInvite: false,
      };
    case REASSIGN_INVITE:
      return {
        ...state,
        isReassigning: true,
      };
    case REASSIGN_INVITE_SUCCESS:
      return {
        ...state,
        isReassigning: false,
        reassignedSuccessfully: true,
      };
    case REASSIGN_INVITE_FAILURE:
      return {
        ...state,
        isReassigning: false,
        reassigningError: payload,
      };
    case SET_CHANNEL:
      return {
        ...state,
        channel: payload,
      };
    default:
      return state;
  }
};
