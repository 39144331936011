import { compose } from 'ramda';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { withFeatureFlagProvider } from '../../infrastructure/featureFlags';
import BrowserLanguageProvider from '../../infrastructure/language/BrowserLanguageProvider';
import VariableBasedLanguageProvider from '../../infrastructure/language/VariableBasedLanguageProvider';
import TranslationProvider from '../../infrastructure/translations/TranslationProvider';
import FailedIdentityProviderSignup from '../Signup/FailedIdentityProviderSignup';
import FinishIdentityProviderSignup from '../Signup/FinishIdentityProviderSignup';
import {
  ROUTE_IDENTITY_PROVIDER_FAILED,
  ROUTE_IDENTITY_PROVIDER_FINISH,
  ROUTE_SIGNUP,
  ROUTE_SIGNUP_CAMPAIGN,
  ROUTE_SIGNUP_EXTERNAL,
  ROUTE_SIGNUP_MOBILE,
  ROUTE_SIGNUP_PARTNER,
  ROUTE_SIGNUP_SANDBOX,
} from '../Signup/routes';
import { ROUTE_USER_INVITE, ROUTE_UPDATE_PROFILE } from '../UserInvite/routes';

import './styles.css';
import { selectLocationType, selectQueryParams } from './selectors';

const UpdateProfile = React.lazy(() => import('../UserInvite/UpdateProfile'));
const InviteContextSwitcher = React.lazy(() => import('../Invites/InviteContextSwitcher'));
const SignupWizard = React.lazy(() => import('../Signup'));
const NotFound = React.lazy(() => import('../../domain/App/NotFound'));

const App = () => {
  const route = useSelector(selectLocationType);
  const queryParams = useSelector(selectQueryParams);
  const showTranslationLabels = queryParams?.translationLabels !== undefined;

  switch (route) {
    case ROUTE_SIGNUP:
    case ROUTE_SIGNUP_MOBILE:
    case ROUTE_SIGNUP_SANDBOX:
    case ROUTE_SIGNUP_EXTERNAL:
    case ROUTE_SIGNUP_CAMPAIGN:
    case ROUTE_SIGNUP_PARTNER:
      return (
        <Suspense fallback={null}>
          <VariableBasedLanguageProvider>
            <TranslationProvider debug={showTranslationLabels}>
              <SignupWizard />
            </TranslationProvider>
          </VariableBasedLanguageProvider>
        </Suspense>
      );
    case ROUTE_USER_INVITE:
      return (
        <Suspense fallback={null}>
          <BrowserLanguageProvider>
            <TranslationProvider debug={showTranslationLabels}>
              <InviteContextSwitcher />
            </TranslationProvider>
          </BrowserLanguageProvider>
        </Suspense>
      );
    case ROUTE_UPDATE_PROFILE:
      return (
        <Suspense fallback={null}>
          <BrowserLanguageProvider>
            <TranslationProvider debug={showTranslationLabels}>
              <UpdateProfile />
            </TranslationProvider>
          </BrowserLanguageProvider>
        </Suspense>
      );
    case ROUTE_IDENTITY_PROVIDER_FINISH:
      return <FinishIdentityProviderSignup />;
    case ROUTE_IDENTITY_PROVIDER_FAILED:
      return <FailedIdentityProviderSignup />;
    default:
      return (
        <Suspense fallback={null}>
          <BrowserLanguageProvider>
            <TranslationProvider debug={showTranslationLabels}>
              <NotFound message="Page Not Found" />
            </TranslationProvider>
          </BrowserLanguageProvider>
        </Suspense>
      );
  }
};

const enhance = compose(withFeatureFlagProvider);

export default enhance(App);
