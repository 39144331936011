import { call, takeEvery } from 'redux-saga/effects';

import { GROUP, IDENTIFY_ANONYMOUS_USER, IDENTIFY_USER, TRACK } from './actions';

export const isAnalyticsAction = ({ type }) => [GROUP, IDENTIFY_USER, IDENTIFY_ANONYMOUS_USER, TRACK].includes(type);

export const track = ({ event, source, meta = {} }) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.track(event, { source, ...meta });
};

const getExtraTraits = () => ({
  screen: {
    width: screen.width,
    height: screen.height,
  },
  window: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

export const identifyUser = ({ employeeId, traits = {} }) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(employeeId, { ...traits, ...getExtraTraits() });
};

export const identifyAnonymousUser = ({ traits }) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify({ ...traits, ...getExtraTraits() });
};

export const group = ({ accountId, traits = {} }) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.group(accountId, traits);
};

export const handleAnalyticsAction = function* ({ type, payload }) {
  if (!__PROD__) {
    const highlightedCss = 'background: lightblue; color: black; padding: 5px;';
    const defaultCss = 'background: none; color: inherit; padding: 0;';
    const args = [
      `Tracked event %c${payload.event}%c with source %c${payload.source}%c and ${
        payload.meta ? 'data' : 'no extra data'
      }`,
      highlightedCss,
      defaultCss,
      highlightedCss,
      defaultCss,
    ];

    if (payload.meta) {
      args.push(payload.meta);
    }

    console.log(...args);
    return;
  }

  yield call(
    {
      [TRACK]: track,
      [GROUP]: group,
      [IDENTIFY_USER]: identifyUser,
      [IDENTIFY_ANONYMOUS_USER]: identifyAnonymousUser,
    }[type],
    payload,
  );
};

export default function* () {
  yield takeEvery(isAnalyticsAction, handleAnalyticsAction);
}
