import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';

import { Consumer } from './localContext';

export const withFeatureFlagConsumer = __DEV__
  ? (WrappedComponent) => (props) => {
      return (
        <Consumer>
          {({ flags, ldClient }) => {
            return <WrappedComponent flags={flags} ldClient={ldClient} {...props} />;
          }}
        </Consumer>
      );
    }
  : withLDConsumer();
