import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import LoadingIndicator from '../../domain/App/LoadingIndicator';
import { reportFetchErrors } from '../../util';
import LanguageContext from '../language/context';

import { fetchTranslationMessages } from './utils';

const fetchTranslationLinks = async () => {
  if (__DEV__) {
    return {};
  }

  const manifest = await reportFetchErrors(fetch(ASSET_MANIFEST_URL));

  if (manifest.signup === undefined) {
    return {};
  }

  return manifest.signup.translations;
};

const getTranslationsPath = (translationLinks, language) => {
  if (__DEV__) {
    return `${PUBLIC_PATH}translations.json`;
  }

  if (translationLinks[language] === undefined) {
    return `${PUBLIC_PATH}web/signup/translations/tlh-KL.json`;
  }

  return `${PUBLIC_PATH}web/signup/translations/${translationLinks[language]}`;
};

const TranslationProvider = ({ children, debug }) => {
  const [isLoading, setLoadingState] = useState(true);
  const [translations, setTranslations] = useState({});

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    let canceled = false;

    fetchTranslationLinks()
      .then((translationLinks) => {
        return fetchTranslationMessages(getTranslationsPath(translationLinks, language));
      })
      .then((messages) => {
        if (!canceled) {
          setTranslations(messages);
          setLoadingState(false);
        }
      });

    return () => (canceled = true);
  }, [language]);

  const messages = useMemo(() => {
    if (debug) {
      return Object.keys(translations).reduce(
        (translationKeys, translationKey) => ({
          ...translationKeys,
          [translationKey]: translationKey,
        }),
        {},
      );
    }

    return translations;
  }, [translations, debug]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return <IntlProvider children={children} locale={language} messages={messages} />;
};

TranslationProvider.propTypes = {
  children: PropTypes.any,
  debug: PropTypes.bool,
};

export default TranslationProvider;
