import { captureException } from '@sentry/react';
import isUndefined from 'lodash/isUndefined';
import { call, delay, put, select } from 'redux-saga/effects';

import { SignupApi } from '../../../infrastructure/api';
import { isError } from '../../../infrastructure/api/types/Error';
import { AccountsSignupWithUserRequest } from '../../../infrastructure/api/types/signup/AccountsSignupWithUserRequest';
import { AccountsSignupWithUserResponse } from '../../../infrastructure/api/types/signup/AccountsSignupWithUserResponse';
import { SagaType } from '../../../infrastructure/api/types/signup/SagaType';
import { selectQueryParams } from '../../App/selectors';
import { pollSignupCompletion } from '../../shared/signup';
import { signupCompleted, signUpFailed, track } from '../actions';
import { emailErrors } from '../constants';
import { AnswerState } from '../types/answerState';
import { QuestionType } from '../types/questionType';

import { getRecaptchaToken, waitForRecaptchaReady } from './recaptcha';

type GeneratorReturnType<T extends Generator> = T extends Generator<unknown, infer R, unknown> ? R : never;

export interface SignupPayload extends AccountsSignupWithUserRequest {}

export const signupWithUser = function* (payload: AccountsSignupWithUserRequest) {
  yield call(waitForRecaptchaReady);
  const recaptchaToken: GeneratorReturnType<ReturnType<typeof getRecaptchaToken>> = yield call(
    getRecaptchaToken,
    'signup/signupWithUser',
  );

  const {
    data: { token, id },
  }: Awaited<AccountsSignupWithUserResponse> = yield call(SignupApi.accounts.signupWithUser, payload, {
    fetchOptions: {
      credentials: 'include',
      ...(recaptchaToken && {
        headers: {
          'G-Recaptcha-Response': recaptchaToken,
        },
      }),
    },
  });

  return {
    token,
    accountId: id,
  };
};

export const handleSignupWithUserError = function* (error: unknown) {
  if (isError(error)) {
    if (error.body.errors?.length && error.body.errors[0].title === 'Key email must be valid') {
      yield put(
        signUpFailed({
          error: {
            field: 'email',
            error: emailErrors.FORMAT,
          },
        }),
      );

      return;
    }

    const errorTitle = error.body.title;
    if (errorTitle === 'User with this email already exists') {
      yield put(
        signUpFailed({
          error: {
            field: 'email',
            error: emailErrors.UNAVAILABLE,
          },
        }),
      );

      return;
    }

    if (errorTitle === 'Email domain can not be used') {
      yield put(
        signUpFailed({
          error: {
            field: 'email',
            error: emailErrors.BLOCKED_DOMAIN,
          },
        }),
      );
      return;
    }
  }

  yield put(signUpFailed({ error: undefined }));
};

export const getCompanyForm = (payload: SignupPayload) => {
  const { survey } = payload;
  const companyFormQuestion = survey.find((answer) => answer.questionType === QuestionType.CompanyForm);
  const companyFormAnswer = companyFormQuestion?.answers.find((answer) => answer.state === AnswerState.Checked);

  return companyFormAnswer?.option;
};

export const getCompanySize = (payload: SignupPayload) => {
  const { survey } = payload;
  const companySizeQuestion = survey.find((answer) => answer.questionType === QuestionType.CompanySize);
  const companySizeAnswer = companySizeQuestion?.answers.find((answer) => answer.state === AnswerState.Checked);

  return companySizeAnswer?.option;
};

export const redirect = function (url: string) {
  window.location.assign(url);
};

export const autoLogin = function* (token: string, companyForm?: string, companySize?: string) {
  yield delay(750);

  const queryParams: { redirect_url: string | undefined } | undefined = yield select(selectQueryParams);
  const queryParamRedirectUri = queryParams?.redirect_url;

  const companyFormParam = !isUndefined(companyForm) ? `&company_form=${companyForm}` : '';
  const companySizeParam = !isUndefined(companySize) ? `&company_size=${companySize}` : '';
  const defaultRedirectUri = `${TEAMLEADER_APP_URL}?welcome=1${companyFormParam}${companySizeParam}`;

  const redirectUri = !isUndefined(queryParamRedirectUri) ? queryParamRedirectUri : defaultRedirectUri;
  const encodedRedirectUri = encodeURIComponent(redirectUri);

  yield call(
    redirect,
    `${AUTHENTICATION_SERVICE_URL}/auth/auto-login?token=${token}&redirect_url=${encodedRedirectUri}`,
  );
};

export const signup = function* (payload: SignupPayload) {
  yield put(
    track({
      event: 'signupWizard.submitted',
      source: 'signupWizard',
    }),
  );

  let token, accountId;
  try {
    const {
      token: apiToken,
      accountId: apiAccountId,
    }: GeneratorReturnType<ReturnType<typeof signupWithUser>> = yield call(signupWithUser, payload);

    token = apiToken;
    accountId = apiAccountId;

    yield call(pollSignupCompletion, payload.id, SagaType.Account);
  } catch (error) {
    yield call(captureException, error);
    yield call(handleSignupWithUserError, error);

    yield put(
      track({
        event: 'signupWizard.submissionFailed',
        source: 'signupWizard',
      }),
    );
    return;
  }

  yield put(signupCompleted());
  yield put(
    track({
      event: 'signupWizard.completed',
      source: 'signupWizard',
      meta: {
        accountId,
      },
    }),
  );
  yield call(autoLogin, token, getCompanyForm(payload), getCompanySize(payload));
};
