import { createAction } from '@reduxjs/toolkit';

import { ROUTE_SIGNUP } from './routes';

export const INIT_SIGNUP = 'Signup/Signup/INIT_SIGNUP';
export const PARSE_IDENTITY_PROVIDER_TOKEN = 'Signup/Signup/PARSE_IDENTITY_PROVIDER_TOKEN';
export const SET_IDENTITY_PROVIDER_ERROR = 'Signup/Signup/SET_IDENTITY_PROVIDER_ERROR';
export const SET_IDENTITY_PROVIDER_TOKEN = 'Signup/Signup/SET_IDENTITY_PROVIDER_TOKEN';
export const VALIDATE_EMAIL = 'Signup/Signup/VALIDATE_EMAIL';
export const EMAIL_VALIDATED = 'Signup/Signup/EMAIL_VALIDATED';
export const SIGN_UP_FAILED = 'Signup/Signup/SIGN_UP_FAILED';
export const CHANGE_FIELD = 'Signup/Signup/CHANGE_FIELD';
export const PREVIOUS_STEP = 'Signup/Signup/PREVIOUS_STEP';
export const SUBMIT_STEP = 'Signup/Signup/SUBMIT_STEP';
export const SIGN_UP_COMPLETED = 'Signup/Signup/SIGN_UP_COMPLETED';
export const TRACK = 'Signup/Signup/TRACK_SIGNUP_ACTION';
export const SET_REFERRAL_TOKEN = 'Signup/Signup/SET_REFERRAL_TOKEN';
export const PREFILL_FIELDS = 'Signup/Signup/PREFILL_FIELDS';
export const SET_INDICATE_FIRST_STEP = 'Signup/Signup/SET_INDICATE_FIRST_STEP';
export const ADD_SIGNUP_FEATURE = 'Signup/Signup/ADD_SIGNUP_FEATURE';
export const REMOVE_SIGNUP_FEATURE = 'Signup/Signup/REMOVE_SIGNUP_FEATURE';
export const REQUEST_SIGNUP_COMPLETION = 'Signup/Signup/REQUEST_SIGNUP_SUCCESS';
export const SIGNUP_SUCCESS = 'Signup/Signup/SIGNUP_SUCCESS';

export const initSignup = createAction(INIT_SIGNUP);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ token: string | null, provider: string | null, recaptchaToken?: string }>} */
export const parseIdentityProviderToken = createAction(PARSE_IDENTITY_PROVIDER_TOKEN);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ message: string | null, provider: string | null } | undefined>} */
export const setIdentityProviderError = createAction(SET_IDENTITY_PROVIDER_ERROR);
export const setIdentityProviderToken = createAction(SET_IDENTITY_PROVIDER_TOKEN);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ email: string | undefined, recaptchaToken?: string }>} */
export const validateEmail = createAction(VALIDATE_EMAIL);
export const emailValidated = createAction(EMAIL_VALIDATED);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ error: { field: string, error: string } | undefined}>} */
export const signUpFailed = createAction(SIGN_UP_FAILED);
export const changeField = createAction(CHANGE_FIELD);
export const previousStep = createAction(PREVIOUS_STEP);
export const submitStep = createAction(SUBMIT_STEP);
export const signupCompleted = createAction(SIGN_UP_COMPLETED);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ event: string, source?: string, meta?: Record<string, unknown> }>} **/
export const track = createAction(TRACK);
export const setReferralToken = createAction(SET_REFERRAL_TOKEN);
export const prefillFields = createAction(PREFILL_FIELDS);
export const setIndicateFirstStep = createAction(SET_INDICATE_FIRST_STEP);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ feature: string }>} */
export const addSignupFeature = createAction(ADD_SIGNUP_FEATURE);
/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<{ feature: string }>} */
export const removeSignupFeature = createAction(REMOVE_SIGNUP_FEATURE);
export const redirectToSignup = createAction(ROUTE_SIGNUP);
