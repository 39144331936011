import { all, spawn } from 'redux-saga/effects';

import accountSetup from '../application/AccountSetup/sagas';
import currentUser from '../application/CurrentUser/sagas';
import invites from '../application/Invites/sagas';
import signup from '../application/Signup/sagas';
import acceptInvite from '../application/UserInvite/AcceptInvite/sagas';
import analytics from '../infrastructure/analytics/sagas';

export default function* () {
  yield all([currentUser, acceptInvite, invites, accountSetup, analytics, signup].map(spawn));
}
