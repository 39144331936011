import { signupAccessTokenFetchUrl } from './constants';

let memoizedAccessToken = null;
let tokenExpiration = null;
let isSettled = false;

const baseAuthorize = async () => {
  const response = await fetch(`${signupAccessTokenFetchUrl}/accessToken.fetch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  return response.json();
};

export const createGetAccessToken = async (authorize) => {
  if (tokenExpiration !== null && !isTokenExpired()) {
    return memoizedAccessToken;
  }

  if (memoizedAccessToken && !isSettled) {
    return memoizedAccessToken;
  }

  isSettled = false;
  const { access_token: accessToken, expires_in: expiresIn } = await authorize();
  tokenExpiration = Date.now() + expiresIn * 1000 * 0.75;
  memoizedAccessToken = accessToken;
  isSettled = true;

  return memoizedAccessToken;
};

export const isTokenExpired = () => {
  return Date.now() > tokenExpiration;
};

export const getAccessToken = (authorize = baseAuthorize) => {
  if (window.location.hash) {
    return Promise.resolve(window.location.hash.substr(1));
  }

  return createGetAccessToken(authorize);
};
