import API from '@teamleader/api';
import jwtDecode from 'jwt-decode';
import { filter, identity } from 'ramda';

import { getTld } from '../../util';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './constants';

const getLanguageFromSessionStorage = () => {
  try {
    const sessionStorageData = JSON.parse(sessionStorage.getItem('accountSetup'));
    if (sessionStorageData?.accountSettings?.language) {
      return sessionStorageData.accountSettings.language;
    }
  } catch (error) {}
};

const getTokenLanguage = (token) => {
  if (!token) {
    return;
  }

  try {
    const tokenData = jwtDecode(token);
    return tokenData?.language;
  } catch (error) {}
};

const getUserProfileLanguage = async (accessToken) => {
  try {
    const { users } = API({
      baseUrl: TEAMLEADER_API,
      accessToken,
    });

    const { data } = await users.me();
    if (data.language) {
      return data.language;
    }
  } catch (error) {}
};

export const getQueryParamLanguage = (lang) => {
  return lang?.toLowerCase();
};

export const getTldLanguage = () => {
  const tld = getTld().toLowerCase();

  if (tld === 'fr') {
    return 'fr-FR';
  } else if (tld === 'nl') {
    return 'nl-NL';
  }

  return tld;
};

export const getBrowserLanguage = () => {
  const browserLanguage = navigator.language;

  if (browserLanguage.indexOf(DEFAULT_LANGUAGE) !== -1) {
    return undefined;
  }

  const tld = getTld().toLowerCase();

  if (browserLanguage === 'nl' && tld === 'nl') {
    return 'nl-NL';
  } else if (browserLanguage === 'fr' && tld === 'fr') {
    return 'fr-FR';
  }

  return browserLanguage;
};

export const getLanguage = async (locationState, accessToken) => {
  const getLanguageSteps = filter(identity, [
    getLanguageFromSessionStorage,
    locationState?.query?.token ? () => getTokenLanguage(locationState.query.token) : undefined,
    accessToken ? async () => await getUserProfileLanguage(accessToken) : undefined,
    locationState?.query?.lang ? () => getQueryParamLanguage(locationState.query.lang) : undefined,
    getBrowserLanguage,
    getTldLanguage,
  ]);

  for (let i = 0; i < getLanguageSteps.length; i += 1) {
    const expectedLanguage = await getLanguageSteps[i]();

    if (expectedLanguage) {
      const validLanguage = SUPPORTED_LANGUAGES.find((lang) => lang.toLowerCase() === expectedLanguage.toLowerCase());
      if (validLanguage) {
        return validLanguage;
      }
    }
  }

  return DEFAULT_LANGUAGE;
};
