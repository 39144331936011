import { always, is, curry, compose, toLower } from 'ramda';

import { ALL_COUNTRY_OPTIONS, LANGUAGES, TIMEZONES, CURRENCIES } from './constants';

export const getBrowserTimeZone = always<string | undefined>(Intl?.DateTimeFormat().resolvedOptions().timeZone);
export const getBrowserLanguage = always(navigator.language);

type TypeValidator<T> = (v: T) => T | undefined;
const stringValidator = curry((v: TypeValidator<string>, value: any) => (is(String, value) ? v(value) : undefined));

export const validateLanguageCode = stringValidator(
  compose((languageCode) => {
    return LANGUAGES.find(({ code }) => code.toLowerCase() === languageCode)?.code;
  }, toLower),
);

export const validateCountryCode = stringValidator(
  compose((countryCode) => {
    return ALL_COUNTRY_OPTIONS.find(({ code }) => code.toLowerCase() === countryCode)?.code;
  }, toLower),
);

export const validateTimeZone = stringValidator((timeZone) => {
  return TIMEZONES.includes(timeZone) ? timeZone : undefined;
});

export const validateCurrency = stringValidator(
  compose((currency) => {
    return CURRENCIES.find(({ code }) => code.toLowerCase() === currency)?.code;
  }, toLower),
);

export const sanitizeParameter = function (suppliedValue: unknown, allowedValues: string[], returnAsArray = false) {
  if (!Array.isArray(allowedValues)) {
    throw new Error('Allowed values should be an array of allowed values.');
  }

  if (typeof suppliedValue === 'undefined') {
    return undefined;
  }

  const valueAsArray = Array.isArray(suppliedValue) ? suppliedValue : [suppliedValue];
  const validItems = valueAsArray.filter((value) => allowedValues.includes(value));
  return returnAsArray ? validItems : validItems[0];
};
