import PropTypes from 'prop-types';
import { curry } from 'ramda';
import React, { useState, useEffect } from 'react';

import { withFeatureFlagConsumer } from './consumer';

export const withFeatureFlagIdentifier = curry((identifyAs, Component) => {
  const Wrapper = ({ ldClient, ...otherProps }) => {
    const [didIdentify, setDidIdentify] = useState(false);

    useEffect(() => {
      if (!didIdentify && ldClient) {
        ldClient.identify(identifyAs).then(
          () => {
            setDidIdentify(true);
          },
          () => {
            setDidIdentify(true);
          },
        );
      }
    }, [didIdentify, ldClient, setDidIdentify]);

    if (!didIdentify) {
      return null;
    }

    return <Component ldClient={ldClient} {...otherProps} />;
  };

  Wrapper.propTypes = {
    ldClient: PropTypes.object,
  };

  return withFeatureFlagConsumer(Wrapper);
});
