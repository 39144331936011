import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect } from 'react';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from './constants';
import LanguageContext from './context';
import { getBrowserLanguage } from './utils';

const getSupportedLanguageOrFallback = (selectedLanguage) => {
  if (!selectedLanguage) {
    return DEFAULT_LANGUAGE;
  }

  return SUPPORTED_LANGUAGES.find((lang) => lang.toLowerCase() === selectedLanguage.toLowerCase()) ?? DEFAULT_LANGUAGE;
};

const BrowserLanguageProvider = ({ children }) => {
  const [language, changeLanguage] = useState(DEFAULT_LANGUAGE);
  const [isLoading, setLoadingState] = useState(true);

  useLayoutEffect(() => {
    const language = getSupportedLanguageOrFallback(getBrowserLanguage());

    changeLanguage(language);
    setLoadingState(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>;
};

BrowserLanguageProvider.propTypes = {
  children: PropTypes.any,
};

export default BrowserLanguageProvider;
