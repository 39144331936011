import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import api from '../../infrastructure/api';
import { SagaType } from '../../infrastructure/api/types/signup/SagaType';
import { pollSignupCompletion } from '../shared/signup';

import { SETUP_ACCOUNT, setupAccountSuccess, setupAccountFailure } from './actions';

export function* setupAccount({ payload }) {
  const { signupId, ...accountSetupPayload } = payload;

  let token;
  try {
    const {
      data: { token: apiToken },
    } = yield call(api.account.setup, accountSetupPayload);

    token = apiToken;

    yield call(pollSignupCompletion, signupId, SagaType.AccountInvite);
  } catch (error) {
    yield put(setupAccountFailure(error));
    return;
  }

  yield put(setupAccountSuccess());
  yield call(redirectToAutoLogin, token, `${TEAMLEADER_APP_URL}?welcome=1`);
}

export function redirectToAutoLogin(token, redirectUri) {
  if (redirectUri) {
    redirectUri = encodeURIComponent(redirectUri);
  }

  window.location.assign(
    `${AUTHENTICATION_SERVICE_URL}/auth/auto-login?token=${token}&return_token=false&redirect_url=${redirectUri}`,
  );
}

export function* setupAccountWatcher() {
  yield takeLatest(SETUP_ACCOUNT, setupAccount);
}

export default function* () {
  yield all([setupAccountWatcher].map(fork));
}
