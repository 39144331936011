import { Consent, ConsentCategory, CookiesConsentPayload } from './types';

const getConsent = (): Consent | null => {
  if (!window.Cookiebot) {
    return null;
  }

  const bulkconsentsubmitted = window.Cookiebot.bulkconsentsubmitted ?? false;
  const consent = bulkconsentsubmitted
    ? window.Cookiebot.bulkconsent ?? window.Cookiebot.consent
    : window.Cookiebot.consent;

  const necessary = true;
  const preferences = consent?.preferences ?? false;
  const statistics = consent?.statistics ?? false;
  const marketing = consent?.marketing ?? false;

  return { necessary, preferences, statistics, marketing };
};

export const getCookieConsentPayload = (): CookiesConsentPayload | null => {
  const consent = getConsent();

  if (!consent) {
    return null;
  }

  return Object.values(ConsentCategory).reduce(
    (payload, category) =>
      consent[category]
        ? { ...payload, allowed: [...payload.allowed, category] }
        : { ...payload, disallowed: [...payload.disallowed, category] },
    { allowed: [], disallowed: [] } as CookiesConsentPayload,
  );
};
