export const ROUTE_SIGNUP = '@@Signup/Signup/Signup';
export const ROUTE_SIGNUP_SANDBOX = '@@Signup/Signup/Sandbox';
export const ROUTE_SIGNUP_EXTERNAL = '@@Signup/Signup/External';
export const ROUTE_SIGNUP_CAMPAIGN = '@@Signup/Signup/Campaign';
export const ROUTE_SIGNUP_MOBILE = '@@Signup/Signup/Mobile';
export const ROUTE_SIGNUP_PARTNER = '@@Signup/Signup/Partner';
export const ROUTE_IDENTITY_PROVIDER_FINISH = '@@Signup/IdentityProviderFinish';
export const ROUTE_IDENTITY_PROVIDER_FAILED = '@@Signup/IdentityProviderFailed';

export default {
  [ROUTE_SIGNUP]: '/',
  [ROUTE_SIGNUP_SANDBOX]: '/sandbox(/.+)?',
  [ROUTE_SIGNUP_EXTERNAL]: '/external(/.+)?',
  [ROUTE_SIGNUP_CAMPAIGN]: '/campaign/:identifier/(.+)?',
  [ROUTE_SIGNUP_PARTNER]: '/partner/:type/:identifier/(.+)?',
  [ROUTE_SIGNUP_MOBILE]: '/mobile',
  [ROUTE_IDENTITY_PROVIDER_FINISH]: '/identity-provider/finish',
  [ROUTE_IDENTITY_PROVIDER_FAILED]: '/identity-provider/failed',
};
