import { all, fork, takeLatest, call, put } from 'redux-saga/effects';

import API from '../../infrastructure/api';
import { SagaType } from '../../infrastructure/api/types/signup/SagaType';
import { pollSignupCompletion } from '../shared/signup';

import {
  VALIDATE_INVITE,
  validateInviteSuccess,
  validateInviteFailure,
  acceptInviteSuccess,
  acceptInviteFailure,
  REASSIGN_INVITE,
  reassignInviteFailure,
  reassignInviteSuccess,
} from './actions';

export function* validateInvite({ payload }) {
  let tokenErrors;
  try {
    ({
      data: { error: tokenErrors },
    } = yield call(API.userInvites.validate, payload));
  } catch (error) {
    yield put(validateInviteFailure(error));
    return;
  }
  yield put(validateInviteSuccess(tokenErrors));
}

export function* acceptInvite({ payload }) {
  const { signupId } = payload;

  let token;
  try {
    const {
      data: { token: apiToken },
    } = yield call(API.userInvites.accept, payload);

    token = apiToken;

    yield call(pollSignupCompletion, signupId, SagaType.UserInvite);
  } catch (error) {
    yield put(acceptInviteFailure(error));
    return;
  }

  yield put(acceptInviteSuccess());
  return token;
}

export function* reassignInvite({ payload }) {
  try {
    yield call(API.userInvites.reassign, payload);
  } catch (error) {
    yield put(reassignInviteFailure(error));
    return;
  }
  yield put(reassignInviteSuccess());
}

export function* validateInviteWatcher() {
  yield takeLatest(VALIDATE_INVITE, validateInvite);
}

export function* reassignInviteWatcher() {
  yield takeLatest(REASSIGN_INVITE, reassignInvite);
}

export default function* () {
  yield all([validateInviteWatcher, reassignInviteWatcher].map(fork));
}
