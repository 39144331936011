import querySerializer from 'query-string';
import { connectRoutes } from 'redux-first-router';

import signup from '../application/Signup/routes';
import userInvite from '../application/UserInvite/routes';

const routesMap = {
  ...userInvite,
  ...signup,
};

const routeOptions = {
  querySerializer,
};

export const { reducer, middleware, enhancer } = connectRoutes(routesMap, routeOptions);
