import * as Sentry from '@sentry/react';
import { curry, any, flatten, toPairs, includes } from 'ramda';

export const containsTerms = curry((terms, obj) => {
  if (obj === null || typeof obj !== 'object') {
    return includes(obj, terms);
  }

  if (Array.isArray(obj)) {
    return any(containsTerms(terms), obj);
  }

  return any(containsTerms(terms), flatten(toPairs(obj)));
});

export const obfuscateKeysWith = curry((withValue, keys, obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(obfuscateKeysWith(withValue, keys));
  }

  return Object.entries(obj).reduce((obfuscated, [key, value]) => {
    if (keys.includes(key)) {
      return {
        ...obfuscated,
        [key]: withValue,
      };
    }

    return {
      ...obfuscated,
      [key]: obfuscateKeysWith(withValue, keys, value),
    };
  }, {});
});

export const obfuscateKeys = obfuscateKeysWith(undefined);

export const objectToQueryString = (o) => {
  const fields = Object.entries(o)
    .map((entry) => entry.map(encodeURIComponent).join('='))
    .join('&');
  return `?${fields}`;
};

export const sortByKey = curry((key, arr) => arr.sort((a, b) => (a[key] > b[key] ? 1 : -1)));

export const capitalise = ([firstChar, ...rest]) => [firstChar.toUpperCase(), ...rest].join('');

export const snakeToCamel = (str) => {
  const [first, ...rest] = str.split('_');
  return [first, ...rest.map(capitalise)].join('');
};

export const getTld = () => {
  let tld = window.location.origin.split('.').pop();

  if (__DEV__) {
    tld = tld.split(':')[0];
  }

  return tld.toUpperCase();
};

export const reportFetchErrors = async (fetchPromise) => {
  try {
    const response = await fetchPromise;

    if (!response.ok) {
      Sentry.captureException(new Error(`${response.status}: ${response.statusText}`));
    }

    return await response.json();
  } catch (error) {
    Sentry.captureException(error);
  }

  return {};
};

export const createArrayShuffler = (randomGenerator) => (arr) => {
  const shuffled = [...arr];

  // Fisher-Yates shuffle
  for (let i = shuffled.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(randomGenerator.next().value * (i + 1));
    [shuffled[i], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[i]];
  }

  return shuffled;
};

export const shuffleArray = createArrayShuffler(
  (function* () {
    while (true) {
      yield Math.random();
    }
  })(),
);
