export const ROUTE_USER_INVITE = '@@Signup/UserInvite/AcceptInvite';
export const ROUTE_UPDATE_PROFILE = '@@Signup/UserInvite/UpdateProfile';

export default {
  [ROUTE_USER_INVITE]: {
    path: '/accept-invite',
  },
  [ROUTE_UPDATE_PROFILE]: {
    path: '/update-profile',
  },
};
