import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './application/App';
import store from './store';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: __ENV__,
  release: `service-signup-frontend@${__VERSION__}`,
  enabled: !__DEV__,
});

const container = document.querySelector('.signup-service-container');
if (container !== null) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
