import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { containsTerms } from '../util';

import reducer from './reducer';
import { middleware as routingMiddleware, enhancer as routingEnhancer } from './routing';
import rootSaga from './sagas';

const sentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (containsTerms(['password', 'token'], action.payload)) {
      return { type: action.type };
    }

    return action;
  },
});

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    if (SENTRY_DSN) {
      Sentry.captureException(error, (scope) => {
        scope.setExtra('sagaStack', sagaStack);
        return scope;
      });
    } else {
      console.error(error);
      console.error(sagaStack);
    }
  },
});

const enhancers = [routingEnhancer, applyMiddleware(routingMiddleware, sagaMiddleware), sentryEnhancer];

if (__DEV__) {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const store = createStore(reducer, compose(...enhancers));

export default store;
export const sagaTask = sagaMiddleware.run(rootSaga);
