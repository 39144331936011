import { SETUP_ACCOUNT, SETUP_ACCOUNT_FAILURE } from './actions';

const initialState = {
  isSettingUpAccount: false,
  setupAccountError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SETUP_ACCOUNT:
      return {
        ...state,
        setupAccountError: null,
        isSettingUpAccount: true,
      };
    case SETUP_ACCOUNT_FAILURE:
      return {
        ...state,
        setupAccountError: payload,
        isSettingUpAccount: false,
      };
    default:
      return state;
  }
};
