import { spawn } from 'redux-saga/effects';

import localStorage from './localStorage';
import prefillForm from './prefillForm';
import steps from './steps';

export default function* () {
  yield spawn(localStorage);
  yield spawn(steps);
  yield spawn(prefillForm);
}
