import API, { camelCase, snakeCase } from '@teamleader/api';

import { baseUrl, apiGatewayBaseUrl, signupApiUrl, referralApiUrl } from './constants';
import { getAccessToken } from './utils';

export const AuthenticatedAPI = API({
  getAccessToken,
  baseUrl: apiGatewayBaseUrl,
  plugins: { response: [camelCase], request: [snakeCase] },
  additionalActions: {
    profile: ['update'],
  },
});

export const SignupApi = API({
  baseUrl: signupApiUrl,
  plugins: { response: [camelCase], request: [snakeCase] },
  additionalActions: {
    accounts: ['signupWithEmail', 'signupWithUser'],
    signup: ['start', 'personalize', 'progress'],
    regionSettings: ['suggest'],
    emailAddresses: ['validate'],
  },
});

export const ReferralApi = API({
  baseUrl: referralApiUrl,
  plugins: { response: [camelCase], request: [snakeCase] },
  additionalActions: {
    referrals: ['identify'],
  },
  fetchOptions: {
    credentials: 'include',
  },
});

export default API({
  getAccessToken: () => (window.location.hash !== '' ? window.location.hash.substr(1) : undefined),
  baseUrl,
  plugins: { response: [camelCase], request: [snakeCase] },
  additionalActions: {
    account: ['setup'],
    userInvites: ['accept', 'reassign', 'validate'],
  },
});
