export const defaultLanguage = 'en';

export const defaultCountry = 'BE';

export const defaultTimeZone = 'Europe/Brussels';

export const defaultCurrency = 'EUR';

export {
  TIMEZONES,
  ALL_COUNTRY_OPTIONS,
  DEFAULT_CURRENCIES,
  OTHER_CURRENCIES,
  SUPPORTED_COUNTRIES,
  OTHER_COUNTRIES,
  LANGUAGES,
  CURRENCIES,
} from '../../domain/AccountSetup/constants';

export const emailErrors = {
  UNAVAILABLE: 'unavailable',
  FORMAT: 'invalid_format',
  INVALID: 'invalid_email',
  BLOCKED_DOMAIN: 'blocked_domain',
};

export const STEP_EMAIL = 'STEP_EMAIL';
export const STEP_PROFILE = 'STEP_PROFILE';
export const STEP_REGION = 'STEP_REGION';
export const STEP_FEATURES = 'STEP_FEATURES';
export const STEP_SUBMITTING = 'STEP_SUBMITTING';

export const STEPS_ORDER = [STEP_EMAIL, STEP_PROFILE, STEP_REGION, STEP_FEATURES, STEP_SUBMITTING];

export const REVIEWS = {
  nl: [
    {
      id: '28284053-a1cc-4ad0-b1c8-f81f0c9a606b',
      summary:
        'Teamleader is een echte boost voor mijn bedrijf. Het geeft me overzicht, meer snelheid en ik ben professioneler.',
      author: 'Wesley declerck',
    },
    {
      id: 'bf872f1a-28cd-4d24-845d-a12d8f65b77e',
      summary:
        'We zijn al tevreden klant sinds 2014. Het maken van facturen, opvolgen van opdrachten,... was nog nooit zo eenvoudig.',
      author: 'Johnny Stegen',
    },
    {
      id: 'f900125d-71d8-494a-8011-15de1aa4af29',
      summary:
        'Teamleader is een topproduct. Hét totaalpakket om onze dagelijkse administratie een pak efficiënter te beheren.',
      author: 'Sol-E-Tech bvba',
    },
    {
      id: '5a91f6cc-aa4f-4b0c-bb15-b7b85a315c2c',
      summary:
        'Teamleader maakt onze opvolging en facturatie zoveel eenvoudiger en overzichtelijker. We zijn zeer tevreden.',
      author: 'Livia Raskin',
    },
  ],
  'nl-nl': [
    {
      id: 'fb290a49-65f0-4a58-a079-bd5b398f4060',
      summary: 'Zeer veel mogelijkheden, gebruiksvriendelijk en zeer goede support.',
      author: 'Daan - DudeSquare van Dijk',
    },
    {
      id: '05e3e475-c43d-459e-abbd-2f2f1e357d97',
      summary: 'Werkt echt. Super dat we nu altijd overzicht hebben van de status van de klant. Support gaat ook goed.',
      author: 'VDS Vloeren',
    },
  ],
  fr: [
    {
      id: 'b04ae69b-3672-4260-ab71-3df16374c90a',
      summary: 'Top produit, top service! Ludique, CRM pour les TPE et PME! Bref un outil simple.',
      author: 'Stéphane Berrebi',
    },
  ],
  en: [
    {
      id: 'e86c2b1b-0ab5-414d-9151-f378faec8fc9',
      summary:
        'Our company has been using Teamleader for several years now. The support staff is excellent. 100% satisfied customer!',
      author: 'Barbara Verplancken',
    },
  ],
  it: [
    {
      id: 'e86c2b1b-0ab5-414d-9151-f378faec8fc9',
      summary:
        'La nostra azienda usa Teamleader da diversi anni. Il personale di supporto è eccellente. Cliente soddisfatto al 100%!',
      author: 'Barbara Verplancken',
    },
  ],
  es: [
    {
      id: 'e86c2b1b-0ab5-414d-9151-f378faec8fc9',
      summary:
        'Nuestra empresa lleva varios años utilizando Teamleader. El equipo de soporte es excelente. Soy un cliente 100% satisfecho. ',
      author: 'Barbara Verplancken',
    },
  ],
  de: [
    {
      id: 'e86c2b1b-0ab5-414d-9151-f378faec8fc9',
      summary:
        'Unser Unternehmen setzt Teamleader seit mehreren Jahren ein. Das Support-Personal ist ausgezeichnet. 100% zufriedener Kunde!',
      author: 'Barbara Verplancken',
    },
  ],
};

export const TL_FOCUS_IDP_SIGNUP = 'TL_FOCUS_IDP_SIGNUP';
export const TL_FOCUS_IDP_SIGNUP_FAILED = 'TL_FOCUS_IDP_SIGNUP_FAILED';
