import { identity, mergeAll, apply } from 'ramda';
import { call, select, all, take, put, takeLatest } from 'redux-saga/effects';

import { SignupApi } from '../../../infrastructure/api';
import { getTld } from '../../../util';
import { INIT_SIGNUP, prefillFields, SET_IDENTITY_PROVIDER_TOKEN, submitStep, track } from '../actions';
import { defaultLanguage, defaultCountry, defaultCurrency, defaultTimeZone, STEPS_ORDER } from '../constants';
import {
  selectCompanyFormParameter,
  selectCompanySizeParameter,
  selectIdentityProviderToken,
  selectLocationQuery,
  selectSignupFeaturesParameter,
} from '../selectors';
import {
  validateLanguageCode,
  validateCountryCode,
  validateCurrency,
  validateTimeZone,
  getBrowserLanguage,
  getBrowserTimeZone,
} from '../util';

import { getSignupDataFromLocalStorage } from './localStorage';

const cacheReturnValue = (fn) => {
  let didCache = false;
  let cachedValue;

  return function* (...args) {
    if (didCache === false) {
      cachedValue = yield call(fn, ...args);
      didCache = true;
    }
    return cachedValue;
  };
};

export const getValuesFromLocalStorage = function* () {
  const { steps, maxStepIndexSeen } = yield call(getSignupDataFromLocalStorage);
  // only take into account steps that were seen by the user.
  const stepNames = STEPS_ORDER.slice(0, maxStepIndexSeen + 1);

  return mergeAll(stepNames.map((stepName) => steps[stepName]));
};

export const getValuesFromIdentityProviderToken = function* () {
  const token = yield select(selectIdentityProviderToken);

  if (token) {
    return {
      email: token.email ?? undefined,
      firstName: token.firstname ?? undefined,
      lastName: token.lastname ?? undefined,
      telephone: token.phone ?? undefined,
    };
  }

  return {};
};

export const cachedGetValuesFromLocalStorage = cacheReturnValue(getValuesFromLocalStorage);

export const getRegionSuggestionsBasedOnIp = (() => {
  let promise;

  return () => {
    if (!promise) {
      promise = new Promise((resolve) => {
        SignupApi.regionSettings.suggest().then(
          (response) => {
            resolve(response.data || {});
          },
          () => {
            resolve({});
          },
        );
      });
    }

    return promise;
  };
})();

export const getValuesFromIpInformation = function* () {
  const { country, timeZone, currency, language } = yield call(getRegionSuggestionsBasedOnIp);

  return {
    country,
    timeZone,
    currency,
    language,
    telephoneCountry: country,
  };
};

export const getValuesFromQueryParameters = function* () {
  const queryParams = (yield select(selectLocationQuery)) || {};

  const companyForm = yield select(selectCompanyFormParameter);
  const companySize = yield select(selectCompanySizeParameter);
  const signupFeatures = yield select(selectSignupFeaturesParameter);

  return {
    language: queryParams.lang,
    telephoneCountry: queryParams.country,
    companyForm,
    companySize,
    signupFeatures,
    ...queryParams,
  };
};

export const getValuesFromBrowserSettings = function* () {
  const tld = (yield call(getTld)).toLowerCase();
  const timeZone = yield call(getBrowserTimeZone);
  let language = yield call(getBrowserLanguage);

  if (language.indexOf(defaultLanguage) >= 0) {
    // don't use the browser language if it's equal to the default language
    language = undefined;
  } else if (tld === 'nl' && language === 'nl') {
    language = 'nl-NL';
  } else if (tld === 'fr' && language === 'fr') {
    language = 'fr-FR';
  }

  return {
    language,
    timeZone,
  };
};

export const getDefaultValues = function* () {
  return {
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    companyForm: undefined,
    companySize: undefined,
    telephone: '',
    country: defaultCountry,
    telephoneCountry: defaultCountry,
    timeZone: defaultTimeZone,
    language: defaultLanguage,
    currency: defaultCurrency,
    signupFeatures: [],
  };
};

export const getValuesFromTld = function* () {
  let language = (yield call(getTld)).toLowerCase();

  if (language === 'fr') {
    language = 'fr-FR';
  }

  if (language === 'nl') {
    language = 'nl-NL';
  }

  return {
    language,
  };
};

export const async = (fn) => {
  const wrapper = function* (...args) {
    return yield call(fn, ...args);
  };

  wrapper.__async = true;

  return wrapper;
};

export const createInitialValueGetter = (fieldName, validateValue, valueGetters) =>
  function* (skipAsync) {
    for (let i = 0; i < valueGetters.length; i += 1) {
      const valueGetter = valueGetters[i];

      if (skipAsync && valueGetter.__async) {
        continue;
      }

      const getterValue = (yield call(valueGetters[i]))[fieldName];
      const validatedValue = getterValue !== undefined ? yield call(validateValue, getterValue) : undefined;

      if (validatedValue !== undefined) {
        return {
          [fieldName]: validatedValue,
        };
      }
    }

    return {};
  };

export const initialValueGetters = [
  ['email', identity, [getValuesFromIdentityProviderToken, cachedGetValuesFromLocalStorage, getDefaultValues]],
  ['firstName', identity, [getValuesFromIdentityProviderToken, cachedGetValuesFromLocalStorage, getDefaultValues]],
  ['lastName', identity, [getValuesFromIdentityProviderToken, cachedGetValuesFromLocalStorage, getDefaultValues]],
  ['companyName', identity, [getValuesFromIdentityProviderToken, cachedGetValuesFromLocalStorage, getDefaultValues]],
  [
    'companyForm',
    identity,
    [
      getValuesFromQueryParameters,
      getValuesFromIdentityProviderToken,
      cachedGetValuesFromLocalStorage,
      getDefaultValues,
    ],
  ],
  [
    'companySize',
    identity,
    [
      getValuesFromQueryParameters,
      getValuesFromIdentityProviderToken,
      cachedGetValuesFromLocalStorage,
      getDefaultValues,
    ],
  ],
  ['telephone', identity, [getValuesFromIdentityProviderToken, cachedGetValuesFromLocalStorage, getDefaultValues]],
  [
    'telephoneCountry',
    validateCountryCode,
    [
      cachedGetValuesFromLocalStorage,
      async(getValuesFromIpInformation),
      getValuesFromQueryParameters,
      getValuesFromTld,
      getDefaultValues,
    ],
  ],
  [
    'country',
    validateCountryCode,
    [
      cachedGetValuesFromLocalStorage,
      async(getValuesFromIpInformation),
      getValuesFromQueryParameters,
      getValuesFromTld,
      getDefaultValues,
    ],
  ],
  [
    'language',
    validateLanguageCode,
    [
      cachedGetValuesFromLocalStorage,
      getValuesFromQueryParameters,
      getValuesFromBrowserSettings,
      getValuesFromTld,
      async(getValuesFromIpInformation),
      getDefaultValues,
    ],
  ],
  [
    'timeZone',
    validateTimeZone,
    [
      cachedGetValuesFromLocalStorage,
      async(getValuesFromIpInformation),
      getValuesFromBrowserSettings,
      getDefaultValues,
    ],
  ],
  [
    'currency',
    validateCurrency,
    [cachedGetValuesFromLocalStorage, async(getValuesFromIpInformation), getDefaultValues],
  ],
  ['signupFeatures', identity, [getValuesFromQueryParameters, cachedGetValuesFromLocalStorage, getDefaultValues]],
].map(apply(createInitialValueGetter));

export const getFieldPrefillValues = function* (skipAsync) {
  return mergeAll(yield all(initialValueGetters.map((getter) => call(getter, skipAsync))));
};

export function* handleSetIdentityProviderToken() {
  const idpToken = yield select(selectIdentityProviderToken);
  if (idpToken && idpToken.email) {
    yield put(submitStep({ stepIndex: 0 }));
    yield put(
      track({
        event: 'signupWizard.loginInfoSubmitted',
        source: 'signupWizard',
      }),
    );
  }
}

export default function* () {
  yield takeLatest(SET_IDENTITY_PROVIDER_TOKEN, handleSetIdentityProviderToken);

  yield take(INIT_SIGNUP);

  // first skip async field prefillers, so we can immediately seed the form without having to wait for request responses
  yield put(prefillFields(yield call(getFieldPrefillValues, true)));
  yield put(prefillFields(yield call(getFieldPrefillValues)));
}
