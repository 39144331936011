import { useEffect } from 'react';

import { IdentityProviderMessageType, IdentityProviderSignupFinishedMessage } from '../identityProvider';

export const IDENTITY_PROVIDER_TOKEN_PARAM = 'identityProviderToken';
export const PROVIDER_PARAM = 'provider';

const FinishIdentityProviderSignup = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (window.opener && params.has(IDENTITY_PROVIDER_TOKEN_PARAM)) {
      const message: IdentityProviderSignupFinishedMessage = {
        type: IdentityProviderMessageType.Finished,
        token: params.get(IDENTITY_PROVIDER_TOKEN_PARAM),
        provider: params.get(PROVIDER_PARAM),
      };
      window.opener.postMessage(message, window.location.origin);
      window.close();
    }
  });

  return null;
};

export default FinishIdentityProviderSignup;
