import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_LANGUAGE } from './constants';
import LanguageContext from './context';
import { getLanguage } from './utils';

const VariableBasedLanguageProvider = ({ children, accessToken }) => {
  const locationState = useSelector((s) => s.location);
  const [language, changeLanguage] = useState(DEFAULT_LANGUAGE);
  const [isLoading, setLoadingState] = useState(true);

  useLayoutEffect(() => {
    getLanguage(locationState, accessToken).then((fetchedLanguage) => {
      changeLanguage(fetchedLanguage);
      setLoadingState(false);
    });
  }, [accessToken, locationState]);

  if (isLoading) {
    return null;
  }

  return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>;
};

VariableBasedLanguageProvider.propTypes = {
  children: PropTypes.any,
  accessToken: PropTypes.string,
};

export default VariableBasedLanguageProvider;
