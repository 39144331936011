import { createAction } from '@reduxjs/toolkit';

export const SET_CHANNEL = 'Signup/UserInvites/SET_CHANNEL';
export const setChannel = createAction(SET_CHANNEL);

export const VALIDATE_INVITE = 'Signup/UserInvites/VALIDATE_INVITE';
export const VALIDATE_INVITE_SUCCESS = 'Signup/UserInvites/VALIDATE_INVITE_SUCCESS';
export const VALIDATE_INVITE_FAILURE = 'Signup/UserInvites/VALIDATE_INVITE_FAILURE';

export const validateInvite = createAction(VALIDATE_INVITE);
export const validateInviteSuccess = createAction(VALIDATE_INVITE_SUCCESS);
export const validateInviteFailure = createAction(VALIDATE_INVITE_FAILURE);

export const ACCEPT_INVITE = 'Signup/UserInvites/ACCEPT_INVITE';
export const ACCEPT_INVITE_SUCCESS = 'Signup/UserInvites/ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'Signup/UserInvites/ACCEPT_INVITE_FAILURE';

export const acceptInvite = createAction(ACCEPT_INVITE);
export const acceptInviteSuccess = createAction(ACCEPT_INVITE_SUCCESS);
export const acceptInviteFailure = createAction(ACCEPT_INVITE_FAILURE);

export const REASSIGN_INVITE = 'Signup/UserInvites/REASSIGN_INVITE';
export const REASSIGN_INVITE_SUCCESS = 'Signup/UserInvites/REASSIGN_INVITE_SUCCESS';
export const REASSIGN_INVITE_FAILURE = 'Signup/UserInvites/REASSIGN_INVITE_FAILURE';

export const reassignInvite = createAction(REASSIGN_INVITE);
export const reassignInviteSuccess = createAction(REASSIGN_INVITE_SUCCESS);
export const reassignInviteFailure = createAction(REASSIGN_INVITE_FAILURE);
