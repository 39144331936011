import { createAction } from '@reduxjs/toolkit';

export const TRACK = 'Signup/Analytics/TRACK';
export const IDENTIFY_USER = 'Signup/Analytics/IDENTIFY_USER';
export const IDENTIFY_ANONYMOUS_USER = 'Signup/Analytics/IDENTIFY_ANONYMOUS_USER';
export const GROUP = 'Signup/Analytics/GROUP';

export const track = createAction(TRACK);
export const identifyUser = createAction(IDENTIFY_USER);
export const identifyAnonymousUser = createAction(IDENTIFY_ANONYMOUS_USER);
export const group = createAction(GROUP);
