import { useEffect } from 'react';

import { IdentityProviderMessageType, IdentityProviderSignupFailedMessage } from '../identityProvider';

export const ERROR_PARAM = 'error';
export const PROVIDER_PARAM = 'provider';

const FailedIdentityProviderSignup = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (window.opener && params.has(ERROR_PARAM)) {
      const message: IdentityProviderSignupFailedMessage = {
        type: IdentityProviderMessageType.Failed,
        provider: params.get(PROVIDER_PARAM),
        error: params.get(ERROR_PARAM),
      };
      window.opener.postMessage(message, window.location.origin);
      window.close();
    }
  });

  return null;
};

export default FailedIdentityProviderSignup;
