import { combineReducers } from 'redux';

import accountSetup from '../application/AccountSetup/reducer';
import currentUser from '../application/CurrentUser/reducer';
import invite from '../application/Invites/reducer';
import signup from '../application/Signup/reducer';

import { reducer as location } from './routing';

export default combineReducers({
  currentUser,
  invite,
  location,
  accountSetup,
  signup,
});
