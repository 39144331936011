import { always } from 'ramda';
import { takeEvery, select, call } from 'redux-saga/effects';

import { obfuscateKeysWith } from '../../../util';
import { SUBMIT_STEP, SIGN_UP_COMPLETED } from '../actions';
import { selectMaxStepIndexSeen, selectSteps } from '../selectors';

export const getLocalStorageKey = always('signup_steps');
export const getPayloadVersion = always(20221109);

export const getFromLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

export const saveToLocalStorage = (key, payload) => {
  try {
    localStorage.setItem(
      key,
      JSON.stringify({
        data: payload,
        meta: {
          savedAt: Date.now(),
          version: getPayloadVersion(),
        },
      }),
    );
  } catch (error) {}
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const saveStepsToLocalStorage = function* () {
  const steps = yield select(selectSteps);
  const maxStepIndexSeen = yield select(selectMaxStepIndexSeen);
  const localStorageKey = yield call(getLocalStorageKey);

  yield call(saveToLocalStorage, localStorageKey, {
    steps: obfuscateKeysWith('', ['password'], steps),
    maxStepIndexSeen,
  });
};

export const clearLocalStorage = function* () {
  const localStorageKey = yield call(getLocalStorageKey);
  yield call(removeFromLocalStorage, localStorageKey);
};

export const getSignupDataFromLocalStorage = function* () {
  const localStorageKey = yield call(getLocalStorageKey);
  const localStorageValue = yield call(getFromLocalStorage, localStorageKey);

  if (!localStorageValue || localStorageValue.meta?.version !== getPayloadVersion()) {
    return {
      steps: {},
      maxStepIndexSeen: 0,
    };
  }

  return {
    steps: localStorageValue.data.steps,
    maxStepIndexSeen: localStorageValue.data.maxStepIndexSeen,
  };
};

export default function* () {
  yield takeEvery(SUBMIT_STEP, saveStepsToLocalStorage);
  yield takeEvery(SIGN_UP_COMPLETED, clearLocalStorage);
}
