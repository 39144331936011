import { all, fork, takeLatest, call, put } from 'redux-saga/effects';

import { UPDATE_PROFILE_SUCCESS } from '../../CurrentUser/actions';
import { acceptInvite as acceptInviteAction } from '../../Invites/actions';
import { acceptInvite } from '../../Invites/sagas';

import { ACCEPT_USER_INVITE } from './actions';

export function* acceptUserInvite({ payload }) {
  yield put(acceptInviteAction());
  const token = yield call(acceptInvite, { payload: payload.values });
  if (token === undefined) {
    return;
  }

  yield call(redirectToAutoLogin, token, `${PUBLIC_PATH}update-profile`);
}

export function* redirectUser() {
  yield call(redirectToApp);
}

export function redirectToApp() {
  window.location.href = `${TEAMLEADER_APP_URL}?welcome=1`;
}

export function redirectToAutoLogin(token, redirectUri) {
  if (redirectUri) {
    redirectUri = encodeURIComponent(redirectUri);
  }

  window.location.assign(
    `${AUTHENTICATION_SERVICE_URL}/auth/auto-login?token=${token}&return_token=true&redirect_url=${redirectUri}`,
  );
}

export function* acceptInviteWatcher() {
  yield takeLatest(ACCEPT_USER_INVITE, acceptUserInvite);
}

export function* updateProfileSuccessWatcher() {
  yield takeLatest(UPDATE_PROFILE_SUCCESS, redirectUser);
}

export default function* () {
  yield all([acceptInviteWatcher, updateProfileSuccessWatcher].map(fork));
}
