import {
  FETCH_USER_INFORMATION,
  FETCH_USER_INFORMATION_SUCCESS,
  FETCH_USER_INFORMATION_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from './actions';

const initialState = {
  fetchingError: null,
  isFetching: false,
  isUpdatingProfile: false,
  updateProfileError: null,
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_INFORMATION:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: payload,
      };
    case FETCH_USER_INFORMATION_FAILURE:
      return {
        ...state,
        fetchingError: payload,
        isFetching: false,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        isUpdatingProfile: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdatingProfile: false,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdatingProfile: false,
        updateProfileError: payload,
      };
  }
  return state;
};
