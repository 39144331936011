import { sortByKey } from '../../util';

export const SUPPORTED_COUNTRIES = sortByKey('name', [
  {
    code: 'BE',
    name: 'Belgium',
    phoneCode: '+32',
  },
  {
    code: 'FR',
    name: 'France',
    phoneCode: '+33',
  },
  {
    code: 'DE',
    name: 'Germany',
    phoneCode: '+49',
  },
  {
    code: 'IT',
    name: 'Italy',
    phoneCode: '+39',
  },
  {
    code: 'NL',
    name: 'Netherlands',
    phoneCode: '+31',
  },
  {
    code: 'ES',
    name: 'Spain',
    phoneCode: '+34',
  },
]);

export const OTHER_COUNTRIES = sortByKey('name', [
  {
    code: 'AD',
    name: 'Andorra',
    phoneCode: '+376',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    phoneCode: '+971',
  },
  {
    code: 'AF',
    name: 'Afghanistan',
    phoneCode: '+93',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    phoneCode: '+1-268',
  },
  {
    code: 'AI',
    name: 'Anguilla',
    phoneCode: '+1-264',
  },
  {
    code: 'AL',
    name: 'Albania',
    phoneCode: '+355',
  },
  {
    code: 'AM',
    name: 'Armenia',
    phoneCode: '+374',
  },
  {
    code: 'AO',
    name: 'Angola',
    phoneCode: '+244',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    phoneCode: '+672',
  },
  {
    code: 'AR',
    name: 'Argentina',
    phoneCode: '+54',
  },
  {
    code: 'AS',
    name: 'American Samoa',
    phoneCode: '+1-684',
  },
  {
    code: 'AT',
    name: 'Austria',
    phoneCode: '+43',
  },
  {
    code: 'AU',
    name: 'Australia',
    phoneCode: '+61',
  },
  {
    code: 'AW',
    name: 'Aruba',
    phoneCode: '+297',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    phoneCode: '+994',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    phoneCode: '+387',
  },
  {
    code: 'BB',
    name: 'Barbados',
    phoneCode: '+1-246',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    phoneCode: '+880',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    phoneCode: '+226',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    phoneCode: '+359',
  },
  {
    code: 'BH',
    name: 'Bahrain',
    phoneCode: '+973',
  },
  {
    code: 'BI',
    name: 'Burundi',
    phoneCode: '+257',
  },
  {
    code: 'BJ',
    name: 'Benin',
    phoneCode: '+229',
  },
  {
    code: 'BM',
    name: 'Bermuda',
    phoneCode: '+1-441',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    phoneCode: '+673',
  },
  {
    code: 'BO',
    name: 'Bolivia, Plurinational State of',
    phoneCode: '+591',
  },
  {
    code: 'BR',
    name: 'Brazil',
    phoneCode: '+55',
  },
  {
    code: 'BS',
    name: 'Bahamas',
    phoneCode: '+1-242',
  },
  {
    code: 'BT',
    name: 'Bhutan',
    phoneCode: '+975',
  },
  {
    code: 'BW',
    name: 'Botswana',
    phoneCode: '+267',
  },
  {
    code: 'BY',
    name: 'Belarus',
    phoneCode: '+375',
  },
  {
    code: 'BZ',
    name: 'Belize',
    phoneCode: '+501',
  },
  {
    code: 'CA',
    name: 'Canada',
    phoneCode: '+1',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    phoneCode: '+61',
  },
  {
    code: 'CD',
    name: 'Congo, Democratic Republic of the',
    phoneCode: '+243',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    phoneCode: '+236',
  },
  {
    code: 'CG',
    name: 'Congo',
    phoneCode: '+242',
  },
  {
    code: 'CH',
    name: 'Switzerland',
    phoneCode: '+41',
  },
  {
    code: 'CI',
    name: "Côte d'Ivoire",
    phoneCode: '+225',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    phoneCode: '+682',
  },
  {
    code: 'CL',
    name: 'Chile',
    phoneCode: '+56',
  },
  {
    code: 'CM',
    name: 'Cameroon',
    phoneCode: '+237',
  },
  {
    code: 'CN',
    name: 'China',
    phoneCode: '+86',
  },
  {
    code: 'CO',
    name: 'Colombia',
    phoneCode: '+57',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    phoneCode: '+506',
  },
  {
    code: 'CU',
    name: 'Cuba',
    phoneCode: '+53',
  },
  {
    code: 'CV',
    name: 'Cabo Verde',
    phoneCode: '+238',
  },
  {
    code: 'CW',
    name: 'Curaçao',
    phoneCode: '+599',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    phoneCode: '+61',
  },
  {
    code: 'CY',
    name: 'Cyprus',
    phoneCode: '+357',
  },
  {
    code: 'CZ',
    name: 'Czechia',
    phoneCode: '+420',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    phoneCode: '+253',
  },
  {
    code: 'DK',
    name: 'Denmark',
    phoneCode: '+45',
  },
  {
    code: 'DM',
    name: 'Dominica',
    phoneCode: '+1-767',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    phoneCode: '+1-809',
  },
  {
    code: 'DZ',
    name: 'Algeria',
    phoneCode: '+213',
  },
  {
    code: 'EC',
    name: 'Ecuador',
    phoneCode: '+593',
  },
  {
    code: 'EE',
    name: 'Estonia',
    phoneCode: '+372',
  },
  {
    code: 'EG',
    name: 'Egypt',
    phoneCode: '+20',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    phoneCode: '+212',
  },
  {
    code: 'ER',
    name: 'Eritrea',
    phoneCode: '+291',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    phoneCode: '+251',
  },
  {
    code: 'FI',
    name: 'Finland',
    phoneCode: '+358',
  },
  {
    code: 'FJ',
    name: 'Fiji',
    phoneCode: '+679',
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    phoneCode: '+500',
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States of',
    phoneCode: '+691',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    phoneCode: '+298',
  },
  {
    code: 'GA',
    name: 'Gabon',
    phoneCode: '+241',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    phoneCode: '+44',
  },
  {
    code: 'GD',
    name: 'Grenada',
    phoneCode: '+1-473',
  },
  {
    code: 'GE',
    name: 'Georgia',
    phoneCode: '+995',
  },
  {
    code: 'GG',
    name: 'Guernsey',
    phoneCode: '+44-1481',
  },
  {
    code: 'GH',
    name: 'Ghana',
    phoneCode: '+233',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    phoneCode: '+350',
  },
  {
    code: 'GL',
    name: 'Greenland',
    phoneCode: '+299',
  },
  {
    code: 'GM',
    name: 'Gambia',
    phoneCode: '+220',
  },
  {
    code: 'GN',
    name: 'Guinea',
    phoneCode: '+224',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    phoneCode: '+240',
  },
  {
    code: 'GR',
    name: 'Greece',
    phoneCode: '+30',
  },
  {
    code: 'GT',
    name: 'Guatemala',
    phoneCode: '+502',
  },
  {
    code: 'GU',
    name: 'Guam',
    phoneCode: '+1-671',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    phoneCode: '+245',
  },
  {
    code: 'GY',
    name: 'Guyana',
    phoneCode: '+592',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    phoneCode: '+852',
  },
  {
    code: 'HN',
    name: 'Honduras',
    phoneCode: '+504',
  },
  {
    code: 'HR',
    name: 'Croatia',
    phoneCode: '+385',
  },
  {
    code: 'HT',
    name: 'Haiti',
    phoneCode: '+509',
  },
  {
    code: 'HU',
    name: 'Hungary',
    phoneCode: '+36',
  },
  {
    code: 'ID',
    name: 'Indonesia',
    phoneCode: '+62',
  },
  {
    code: 'IE',
    name: 'Ireland',
    phoneCode: '+353',
  },
  {
    code: 'IL',
    name: 'Israel',
    phoneCode: '+972',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
    phoneCode: '+44-1624',
  },
  {
    code: 'IN',
    name: 'India',
    phoneCode: '+91',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    phoneCode: '+246',
  },
  {
    code: 'IQ',
    name: 'Iraq',
    phoneCode: '+964',
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
    phoneCode: '+98',
  },
  {
    code: 'IS',
    name: 'Iceland',
    phoneCode: '+354',
  },
  {
    code: 'JE',
    name: 'Jersey',
    phoneCode: '+44-1534',
  },
  {
    code: 'JM',
    name: 'Jamaica',
    phoneCode: '+1-876',
  },
  {
    code: 'JO',
    name: 'Jordan',
    phoneCode: '+962',
  },
  {
    code: 'JP',
    name: 'Japan',
    phoneCode: '+81',
  },
  {
    code: 'KE',
    name: 'Kenya',
    phoneCode: '+254',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    phoneCode: '+996',
  },
  {
    code: 'KH',
    name: 'Cambodia',
    phoneCode: '+855',
  },
  {
    code: 'KI',
    name: 'Kiribati',
    phoneCode: '+686',
  },
  {
    code: 'KM',
    name: 'Comoros',
    phoneCode: '+269',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    phoneCode: '+1-869',
  },
  {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
    phoneCode: '+850',
  },
  {
    code: 'KR',
    name: 'Korea, Republic of',
    phoneCode: '+82',
  },
  {
    code: 'KW',
    name: 'Kuwait',
    phoneCode: '+965',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    phoneCode: '+1-345',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    phoneCode: '+7',
  },
  {
    code: 'LA',
    name: "Lao People's Democratic Republic",
    phoneCode: '+856',
  },
  {
    code: 'LB',
    name: 'Lebanon',
    phoneCode: '+961',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    phoneCode: '+1-758',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    phoneCode: '+423',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    phoneCode: '+94',
  },
  {
    code: 'LR',
    name: 'Liberia',
    phoneCode: '+231',
  },
  {
    code: 'LS',
    name: 'Lesotho',
    phoneCode: '+266',
  },
  {
    code: 'LT',
    name: 'Lithuania',
    phoneCode: '+370',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    phoneCode: '+352',
  },
  {
    code: 'LV',
    name: 'Latvia',
    phoneCode: '+371',
  },
  {
    code: 'LY',
    name: 'Libya',
    phoneCode: '+218',
  },
  {
    code: 'MA',
    name: 'Morocco',
    phoneCode: '+212',
  },
  {
    code: 'MC',
    name: 'Monaco',
    phoneCode: '+377',
  },
  {
    code: 'MD',
    name: 'Moldova, Republic of',
    phoneCode: '+373',
  },
  {
    code: 'ME',
    name: 'Montenegro',
    phoneCode: '+382',
  },
  {
    code: 'MF',
    name: 'Saint Martin, (French part)',
    phoneCode: '+590',
  },
  {
    code: 'MG',
    name: 'Madagascar',
    phoneCode: '+261',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    phoneCode: '+692',
  },
  {
    code: 'MK',
    name: 'North Macedonia',
    phoneCode: '+389',
  },
  {
    code: 'ML',
    name: 'Mali',
    phoneCode: '+223',
  },
  {
    code: 'MM',
    name: 'Myanmar',
    phoneCode: '+95',
  },
  {
    code: 'MN',
    name: 'Mongolia',
    phoneCode: '+976',
  },
  {
    code: 'MO',
    name: 'Macao',
    phoneCode: '+853',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    phoneCode: '+1-670',
  },
  {
    code: 'MR',
    name: 'Mauritania',
    phoneCode: '+222',
  },
  {
    code: 'MS',
    name: 'Montserrat',
    phoneCode: '+1-664',
  },
  {
    code: 'MT',
    name: 'Malta',
    phoneCode: '+356',
  },
  {
    code: 'MU',
    name: 'Mauritius',
    phoneCode: '+230',
  },
  {
    code: 'MV',
    name: 'Maldives',
    phoneCode: '+960',
  },
  {
    code: 'MW',
    name: 'Malawi',
    phoneCode: '+265',
  },
  {
    code: 'MX',
    name: 'Mexico',
    phoneCode: '+52',
  },
  {
    code: 'MY',
    name: 'Malaysia',
    phoneCode: '+60',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    phoneCode: '+258',
  },
  {
    code: 'NA',
    name: 'Namibia',
    phoneCode: '+264',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    phoneCode: '+687',
  },
  {
    code: 'NE',
    name: 'Niger',
    phoneCode: '+227',
  },
  {
    code: 'NG',
    name: 'Nigeria',
    phoneCode: '+234',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    phoneCode: '+505',
  },
  {
    code: 'NO',
    name: 'Norway',
    phoneCode: '+47',
  },
  {
    code: 'NP',
    name: 'Nepal',
    phoneCode: '+977',
  },
  {
    code: 'NR',
    name: 'Nauru',
    phoneCode: '+674',
  },
  {
    code: 'NU',
    name: 'Niue',
    phoneCode: '+683',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    phoneCode: '+64',
  },
  {
    code: 'OM',
    name: 'Oman',
    phoneCode: '+968',
  },
  {
    code: 'PA',
    name: 'Panama',
    phoneCode: '+507',
  },
  {
    code: 'PE',
    name: 'Peru',
    phoneCode: '+51',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    phoneCode: '+689',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    phoneCode: '+675',
  },
  {
    code: 'PH',
    name: 'Philippines',
    phoneCode: '+63',
  },
  {
    code: 'PK',
    name: 'Pakistan',
    phoneCode: '+92',
  },
  {
    code: 'PL',
    name: 'Poland',
    phoneCode: '+48',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    phoneCode: '+508',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
    phoneCode: '+64',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    phoneCode: '+1-787',
  },
  {
    code: 'PS',
    name: 'Palestine, State of',
    phoneCode: '+970',
  },
  {
    code: 'PT',
    name: 'Portugal',
    phoneCode: '+351',
  },
  {
    code: 'PW',
    name: 'Palau',
    phoneCode: '+680',
  },
  {
    code: 'PY',
    name: 'Paraguay',
    phoneCode: '+595',
  },
  {
    code: 'QA',
    name: 'Qatar',
    phoneCode: '+974',
  },
  {
    code: 'RO',
    name: 'Romania',
    phoneCode: '+40',
  },
  {
    code: 'RS',
    name: 'Serbia',
    phoneCode: '+281',
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    phoneCode: '+7',
  },
  {
    code: 'RW',
    name: 'Rwanda',
    phoneCode: '+250',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    phoneCode: '+966',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    phoneCode: '+677',
  },
  {
    code: 'SC',
    name: 'Seychelles',
    phoneCode: '+248',
  },
  {
    code: 'SD',
    name: 'Sudan',
    phoneCode: '+249',
  },
  {
    code: 'SE',
    name: 'Sweden',
    phoneCode: '+46',
  },
  {
    code: 'SG',
    name: 'Singapore',
    phoneCode: '+65',
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    phoneCode: '+290',
  },
  {
    code: 'SI',
    name: 'Slovenia',
    phoneCode: '+386',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
    phoneCode: '+47',
  },
  {
    code: 'SK',
    name: 'Slovakia',
    phoneCode: '+421',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    phoneCode: '+23é',
  },
  {
    code: 'SM',
    name: 'San Marino',
    phoneCode: '+378',
  },
  {
    code: 'SN',
    name: 'Senegal',
    phoneCode: '+221',
  },
  {
    code: 'SO',
    name: 'Somalia',
    phoneCode: '+252',
  },
  {
    code: 'SR',
    name: 'Suriname',
    phoneCode: '+597',
  },
  {
    code: 'SS',
    name: 'South Sudan',
    phoneCode: '+211',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    phoneCode: '+239',
  },
  {
    code: 'SV',
    name: 'El Salvador',
    phoneCode: '+503',
  },
  {
    code: 'SX',
    name: 'Sint Maarten, (Dutch part)',
    phoneCode: '+1-721',
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
    phoneCode: '+963',
  },
  {
    code: 'SZ',
    name: 'Eswatini',
    phoneCode: '+268',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    phoneCode: '+649',
  },
  {
    code: 'TD',
    name: 'Chad',
    phoneCode: '+235',
  },
  {
    code: 'TG',
    name: 'Togo',
    phoneCode: '+228',
  },
  {
    code: 'TH',
    name: 'Thailand',
    phoneCode: '+66',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    phoneCode: '+992',
  },
  {
    code: 'TK',
    name: 'Tokelau',
    phoneCode: '+690',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    phoneCode: '+670',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    phoneCode: '+993',
  },
  {
    code: 'TN',
    name: 'Tunisia',
    phoneCode: '+216',
  },
  {
    code: 'TO',
    name: 'Tonga',
    phoneCode: '+676',
  },
  {
    code: 'TR',
    name: 'Turkey',
    phoneCode: '+90',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    phoneCode: '+1-868',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    phoneCode: '+688',
  },
  {
    code: 'TW',
    name: 'Taiwan, Province of China',
    phoneCode: '+886',
  },
  {
    code: 'TZ',
    name: 'Tanzania, United Republic of',
    phoneCode: '+255',
  },
  {
    code: 'UA',
    name: 'Ukraine',
    phoneCode: '+380',
  },
  {
    code: 'UG',
    name: 'Uganda',
    phoneCode: '+256',
  },
  {
    code: 'US',
    name: 'United States of America',
    phoneCode: '+1',
  },
  {
    code: 'UY',
    name: 'Uruguay',
    phoneCode: '+598',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    phoneCode: '+998',
  },
  {
    code: 'VA',
    name: 'Holy See',
    phoneCode: '+379',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    phoneCode: '+1-784',
  },
  {
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
    phoneCode: '+58',
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
    phoneCode: '+1-284',
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
    phoneCode: '+1-340',
  },
  {
    code: 'VN',
    name: 'Viet Nam',
    phoneCode: '+84',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    phoneCode: '+678',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
    phoneCode: '+681',
  },
  {
    code: 'WS',
    name: 'Samoa',
    phoneCode: '+685',
  },
  {
    code: 'YE',
    name: 'Yemen',
    phoneCode: '+967',
  },
  {
    code: 'ZA',
    name: 'South Africa',
    phoneCode: '+27',
  },
  {
    code: 'ZM',
    name: 'Zambia',
    phoneCode: '+260',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    phoneCode: '+263',
  },
]);

export const ALL_COUNTRY_OPTIONS = [...SUPPORTED_COUNTRIES, ...OTHER_COUNTRIES];

export const DEFAULT_CURRENCIES = [
  {
    code: 'EUR',
    name: 'Euro',
  },
];

export const OTHER_CURRENCIES = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
  },
  {
    code: 'AFN',
    name: 'Afghan Afghani',
  },
  {
    code: 'ALL',
    name: 'Albanian Lek',
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
  },
  {
    code: 'AOA',
    name: 'Angolan Kwanza',
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
  },
  {
    code: 'AWG',
    name: 'Aruban Florin',
  },
  {
    code: 'AZN',
    name: 'Azerbaijani Manat',
  },
  {
    code: 'BAM',
    name: 'Bosnia-Herzegovina Convertible Mark',
  },
  {
    code: 'BBD',
    name: 'Barbadian Dollar',
  },
  {
    code: 'BDT',
    name: 'Bangladeshi Taka',
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
  },
  {
    code: 'BIF',
    name: 'Burundian Franc',
  },
  {
    code: 'BMD',
    name: 'Bermudan Dollar',
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
  },
  {
    code: 'BOB',
    name: 'Bolivian Boliviano',
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
  },
  {
    code: 'BTN',
    name: 'Bhutanese Ngultrum',
  },
  {
    code: 'BWP',
    name: 'Botswanan Pula',
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colón',
  },
  {
    code: 'CUC',
    name: 'Cuban Convertible Peso',
  },
  {
    code: 'CUP',
    name: 'Cuban Peso',
  },
  {
    code: 'CVE',
    name: 'Cape Verdean Escudo',
  },
  {
    code: 'CZK',
    name: 'Czech Republic Koruna',
  },
  {
    code: 'DJF',
    name: 'Djiboutian Franc',
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
  },
  {
    code: 'ERN',
    name: 'Eritrean Nakfa',
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
  },
  {
    code: 'FJD',
    name: 'Fijian Dollar',
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
  },
  {
    code: 'GBP',
    name: 'British Pound Sterling',
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
  },
  {
    code: 'GGP',
    name: 'Guernsey Pound',
  },
  {
    code: 'GHS',
    name: 'Ghanaian Cedi',
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
  },
  {
    code: 'GMD',
    name: 'Gambian Dalasi',
  },
  {
    code: 'GNF',
    name: 'Guinean Franc',
  },
  {
    code: 'GTQ',
    name: 'Guatemalan Quetzal',
  },
  {
    code: 'GYD',
    name: 'Guyanaese Dollar',
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
  },
  {
    code: 'HNL',
    name: 'Honduran Lempira',
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
  },
  {
    code: 'HTG',
    name: 'Haitian Gourde',
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
  },
  {
    code: 'ILS',
    name: 'Israeli New Sheqel',
  },
  {
    code: 'IMP',
    name: 'Manx pound',
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
  },
  {
    code: 'ISK',
    name: 'Icelandic Króna',
  },
  {
    code: 'JEP',
    name: 'Jersey Pound',
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
  },
  {
    code: 'KGS',
    name: 'Kyrgystani Som',
  },
  {
    code: 'KHR',
    name: 'Cambodian Riel',
  },
  {
    code: 'KMF',
    name: 'Comorian Franc',
  },
  {
    code: 'KPW',
    name: 'North Korean Won',
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
  },
  {
    code: 'KZT',
    name: 'Kazakhstani Tenge',
  },
  {
    code: 'LAK',
    name: 'Laotian Kip',
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
  },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
  },
  {
    code: 'LSL',
    name: 'Lesotho Loti',
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
  },
  {
    code: 'MGA',
    name: 'Malagasy Ariary',
  },
  {
    code: 'MKD',
    name: 'Macedonian Denar',
  },
  {
    code: 'MMK',
    name: 'Myanma Kyat',
  },
  {
    code: 'MNT',
    name: 'Mongolian Tugrik',
  },
  {
    code: 'MOP',
    name: 'Macanese Pataca',
  },
  {
    code: 'MRU',
    name: 'Mauritanian Ouguiya',
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
  },
  {
    code: 'MVR',
    name: 'Maldivian Rufiyaa',
  },
  {
    code: 'MWK',
    name: 'Malawian Kwacha',
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
  },
  {
    code: 'MZN',
    name: 'Mozambican Metical',
  },
  {
    code: 'NAD',
    name: 'Namibian Dollar',
  },
  {
    code: 'NGN',
    name: 'Nigerian Naira',
  },
  {
    code: 'NIO',
    name: 'Nicaraguan Córdoba',
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    code: 'PAB',
    name: 'Panamanian Balboa',
  },
  {
    code: 'PEN',
    name: 'Peruvian Nuevo Sol',
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean Kina',
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
  },
  {
    code: 'PKR',
    name: 'Pakistani Rupee',
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
  },
  {
    code: 'PYG',
    name: 'Paraguayan Guarani',
  },
  {
    code: 'QAR',
    name: 'Qatari Rial',
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
  },
  {
    code: 'RWF',
    name: 'Rwandan Franc',
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
  },
  {
    code: 'SCR',
    name: 'Seychellois Rupee',
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
  },
  {
    code: 'SLL',
    name: 'Sierra Leonean Leone',
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
  },
  {
    code: 'SRD',
    name: 'Surinamese Dollar',
  },
  {
    code: 'STD',
    name: 'São Tomé and Príncipe Dobra (pre-2018)',
  },
  {
    code: 'SVC',
    name: 'Salvadoran Colón',
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
  },
  {
    code: 'SZL',
    name: 'Swazi Lilangeni',
  },
  {
    code: 'THB',
    name: 'Thai Baht',
  },
  {
    code: 'TJS',
    name: 'Tajikistani Somoni',
  },
  {
    code: 'TMT',
    name: 'Turkmenistani Manat',
  },
  {
    code: 'TOP',
    name: "Tongan Pa'anga",
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
  },
  {
    code: 'UGX',
    name: 'Ugandan Shilling',
  },
  {
    code: 'USD',
    name: 'United States Dollar',
  },
  {
    code: 'UYU',
    name: 'Uruguayan Peso',
  },
  {
    code: 'UZS',
    name: 'Uzbekistan Som',
  },
  {
    code: 'VEF',
    name: 'Venezuelan Bolívar Fuerte (Old)',
  },
  {
    code: 'VND',
    name: 'Vietnamese Dong',
  },
  {
    code: 'VUV',
    name: 'Vanuatu Vatu',
  },
  {
    code: 'WST',
    name: 'Samoan Tala',
  },
  {
    code: 'XAF',
    name: 'CFA Franc BEAC',
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
  },
  {
    code: 'XOF',
    name: 'CFA Franc BCEAO',
  },
  {
    code: 'XPF',
    name: 'CFP Franc',
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
  },
  {
    code: 'ZMW',
    name: 'Zambian Kwacha',
  },
  {
    code: 'ZWL',
    name: 'Zimbabwean Dollar',
  },
];

export const CURRENCIES = DEFAULT_CURRENCIES.concat(OTHER_CURRENCIES);

const avialableLanguages = [
  {
    code: 'nl',
    ietf: 'nl-BE',
    name: 'Nederlands (Belgium)',
  },
  {
    code: 'en',
    ietf: 'en-GB',
    name: 'English',
  },
  {
    code: 'fr',
    ietf: 'fr-BE',
    name: 'Français (Belgium)',
  },
  {
    code: 'fr-FR',
    ietf: 'fr-FR',
    name: 'Français (France)',
  },
  {
    code: 'de',
    ietf: 'de-DE',
    name: 'Deutsch',
  },
  {
    code: 'de-CH',
    ietf: 'de-CH',
    name: 'Deutsch (Switzerland)',
  },
  {
    code: 'es',
    ietf: 'es-ES',
    name: 'Español',
  },
  {
    code: 'it',
    ietf: 'it-IT',
    name: 'Italiano',
  },
  {
    code: 'nl-NL',
    ietf: 'nl-NL',
    name: 'Nederlands (Netherlands)',
  },
  {
    code: 'da',
    ietf: 'da-DK',
    name: 'Dansk (beta)',
  },
  {
    code: 'pt',
    ietf: 'pt-PT',
    name: 'Português (beta)',
  },
  {
    code: 'pl',
    ietf: 'pl-PL',
    name: 'Polski (beta)',
  },
  {
    code: 'tr',
    ietf: 'tr-TR',
    name: 'Türkçe (beta)',
  },
  {
    code: 'no',
    ietf: 'nb-NO',
    name: 'Norsk (beta)',
  },
  {
    code: 'fi',
    ietf: 'fi-FI',
    name: 'Suomi (beta)',
  },
  {
    code: 'sv',
    ietf: 'sv-SE',
    name: 'Svenska (beta)',
  },
];

if (ENABLE_KLINGON) {
  avialableLanguages.unshift({
    code: 'tlh-KL',
    ietf: 'tlh-KL',
    name: 'Klingon',
  });
}

export const LANGUAGES = avialableLanguages;

export const TIMEZONES = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmara',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port_of_Spain',
  'America/Port-au-Prince',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Ho_Chi_Minh',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Khandyga',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Athens',
  'Europe/Belfast',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Nicosia',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Tiraspol',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Chuuk',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Pohnpei',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Wake',
  'Pacific/Wallis',
];
