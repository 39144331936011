export const DEFAULT_LANGUAGE = 'en';

const availableLanguages = [
  'fi',
  'fr',
  'de',
  'de-CH',
  'fr-BE',
  'it',
  'en',
  'pt',
  'tlh-KL',
  'nl-BE',
  'de-DE',
  'no',
  'pt-PT',
  'tr',
  'fr-FR',
  'es',
  'nl',
  'it-IT',
  'es-ES',
  'sv-SE',
  'da',
  'sv',
  'pl',
  'nl-NL',
];

if (ENABLE_KLINGON) {
  availableLanguages.unshift('tlh-KL');
}

export const SUPPORTED_LANGUAGES = availableLanguages;
